import React, {
  memo, useEffect, useRef, useState, useContext,
} from 'react';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';
import { useDebouncedCallback } from 'use-debounce';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import moment from 'moment';

import 'react-dates/lib/css/_datepicker.css';

import { theme } from '@tourop/config/theme';

import { DATE_DISPLAY_FORMAT, DATE_REQUEST_FORMAT } from '@ess/constants/api';

import { manualProvidedDateValidator } from '@ess/utils';

import usePopperPositioning from '@ess/hooks/usePopperPositioning';
import useOnClickOutside from '@ess/hooks/useOnClickOutside';

import Modal from '@ess/ui/Modal';
import TextInput from '@ess/ui/Form/TextInput';
import FlexBox from '@ess/ui/FlexBox';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import { NextBtn, PrevBtn } from './ReplacedComponents/NavigationButtons';

import Presets, { IPreset } from './Presets';

export type DateRangePickerProps = {
  startDateValue: any
  endDateValue: any
  minDate?: any
  maxDate?: any
  startDateId: string
  endDateId: string
  startDatePlaceholder: string
  endDatePlaceholder: string
  startDateInputValue: string
  endDateInputValue: string
  onCalendarChange?: (values: any) => void
  onStartDateInputChange?: (value: any) => void
  onEndDateInputChange?: (value: any) => void
  isClearable?: boolean
  onClose?: () => void
  readOnly?: boolean
  appendTo?: Element | null
  showCalendar?: boolean
  customSettings?: boolean
  presets?: IPreset[]
  numberOfMonths?: number
}

const defaultProps = {
  minDate: moment(),
  maxDate: undefined,
  readOnly: false,
  showCalendar: false,
  onCalendarChange: undefined,
  onStartDateInputChange: undefined,
  onEndDateInputChange: undefined,
  onClose: undefined,
  isClearable: false,
  appendTo: undefined,
  customSettings: false,
  numberOfMonths: 2,
  presets: [],
};

const getDisplayDateFormat = (date: string) => {
  const displayDateFormat = moment(date, DATE_REQUEST_FORMAT, true);

  return moment(displayDateFormat).isValid() ? displayDateFormat.format(DATE_DISPLAY_FORMAT) : '';
};

const DateRangePicker = ({
  startDateValue,
  endDateValue,
  startDateInputValue,
  endDateInputValue,
  minDate,
  maxDate,
  startDateId,
  endDateId,
  startDatePlaceholder,
  endDatePlaceholder,
  onCalendarChange,
  onClose,
  appendTo,
  presets,
  customSettings,
  isClearable,
  numberOfMonths,
  onStartDateInputChange,
  onEndDateInputChange,
}: DateRangePickerProps) => {
  const inputsContainer = useRef<HTMLDivElement>();
  const [calendarKey, setCalendarKey] = useState(nanoid());
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [startDateElement, setStartDateElement] = useState<HTMLInputElement | null>(null);
  const [endDateElement, setEndDateElement] = useState<HTMLInputElement | null>(null);
  const [isCalendarChange, setIsCalendarChange] = useState(true);
  const { state: SFContext } = useContext(AppConfigContext);
  const { language } = SFContext;
  const mobile = useMediaQuery({ minWidth: 0, maxWidth: 768 });
  const { styles, attributes } = usePopperPositioning({
    targetElementRef: inputsContainer,
    popperElementRef: popperElement,
    zIndex: theme.zIndex.modal,
    padding: 15,
  });
  moment.locale(language);

  /**
   * Indicates if day is outside of range.
   * @param day
   */
  const isOutsideRange = (day: any) => {
    const isBefore = minDate && !minDate.isSame(day) ? day.isBefore(minDate, 'day') : false;
    const isAfter = maxDate && !maxDate.isSame(day) ? day.isAfter(maxDate, 'day') : false;

    return isBefore || isAfter;
  };

  /**
   * Focus change handler.
   * @param input
   */
  const onFocusChange = (input: any) => {
    setFocusedInput(input);
  };

  /**
   * Start date input focusout handler.
   * @param event
   */
  const onStartDateInputBlur = (event: React.FocusEvent) => {
    if (startDateElement !== null) {
      const date = manualProvidedDateValidator({ date: startDateElement.value });
      const previousDate = moment(startDateInputValue).isValid()
        ? moment(startDateInputValue).format(DATE_REQUEST_FORMAT) : moment().format(DATE_REQUEST_FORMAT);

      if (onStartDateInputChange) {
        if (date === null) {
          onStartDateInputChange(previousDate);
        } else if (previousDate) {
          onStartDateInputChange(previousDate);
        }
      }
    }
  };

  /**
   * Start date input change handler.
   * @param event
   */
  const onStartDateChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    const date = manualProvidedDateValidator({ date: value });

    setIsCalendarChange(false);

    if (onStartDateInputChange) {
      onStartDateInputChange(date);
    }
  }, 100);

  /**
   * End date input focusout handler.
   * @param event
   */
  const onEndDateInputBlur = (event: React.FocusEvent) => {
    if (endDateElement !== null) {
      const date = manualProvidedDateValidator({ date: endDateElement.value });
      const previousDate = moment(endDateInputValue).isValid()
        ? moment(endDateInputValue).format(DATE_REQUEST_FORMAT)
        : moment(startDateInputValue).add(7, 'days').format(DATE_REQUEST_FORMAT);

      if (onEndDateInputChange) {
        if (date === null) {
          onEndDateInputChange(previousDate);
        } else if (previousDate) {
          onEndDateInputChange(previousDate);
        }
      }
    }
  };

  /**
   * End date input change handler.
   * @param event
   */
  const onEndDateChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    const date = manualProvidedDateValidator({ date: value });

    setIsCalendarChange(false);

    if (onEndDateInputChange) {
      onEndDateInputChange(date);
    }
  }, 100);

  /**
   * Calendar dates change handler.
   * @param values
   */
  const onDatesChange = (values: any) => {
    setIsCalendarChange(true);

    if (onCalendarChange) {
      onCalendarChange(values);
    }
  };

  /**
   * Close calendar handler.
   */
  const closeCalendar = () => {
    setFocusedInput(null);

    if (onClose) {
      onClose();
    }
  };

  /**
   * Preset dates click handler (today, month etc.)
   * @param values
   */
  const onPresetClickHandler = (values: any) => {
    onDatesChange(values);
    setFocusedInput(null);
  };

  const dateFromElement = ({ isModal = false, readOnly = false }) => (
    <TextInput
      ref={setStartDateElement as any}
      name={startDateId}
      maxLength={10}
      placeholder={startDatePlaceholder}
      readOnly={readOnly}
      value={getDisplayDateFormat(startDateInputValue) || startDateInputValue}
      onFocus={() => setFocusedInput('startDate')}
      onBlur={onStartDateInputBlur}
      isClearable={isClearable}
      onChange={onStartDateChange}
      onClear={() => {
        setFocusedInput('startDate');
        onDatesChange({
          startDate: null,
          endDate: endDateValue,
        });
      }}
      style={!isModal ? {
        borderRight: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        ...focusedInput === 'startDate' ? {
          backgroundColor: '#e6f1ff',
        } : {},
      } : {}}
    />
  );

  const dateToElement = ({ isModal = false, readOnly = false }) => (
    <TextInput
      ref={setEndDateElement as any}
      placeholder={endDatePlaceholder}
      readOnly={readOnly}
      maxLength={10}
      value={getDisplayDateFormat(endDateInputValue) || endDateInputValue}
      name={endDateId}
      isClearable={isClearable}
      onFocus={() => setFocusedInput('endDate')}
      onBlur={onEndDateInputBlur}
      onChange={onEndDateChange}
      onClear={() => {
        onDatesChange({
          startDate: startDateValue,
          endDate: null,
        });
        setFocusedInput('endDate');
      }}
      style={!isModal ? {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        ...focusedInput === 'endDate' ? {
          backgroundColor: '#e6f1ff',
        } : {},
      } : {}}
    />
  );

  useOnClickOutside(popperElement, closeCalendar, [
    ...startDateElement !== null ? [startDateElement] : [],
    ...endDateElement !== null ? [endDateElement] : [],
  ]);

  useEffect(() => {
    if (!isCalendarChange && getDisplayDateFormat(startDateInputValue)) {
      setCalendarKey(nanoid());
    }
  }, [startDateInputValue, isCalendarChange]);

  useEffect(() => {
    if (!isCalendarChange && getDisplayDateFormat(endDateInputValue)) {
      setCalendarKey(nanoid());
    }
  }, [endDateInputValue, isCalendarChange]);

  useEffect(() => {
    setCalendarKey(nanoid());
  }, [focusedInput]);

  return (
    <>
      <FlexBox ref={inputsContainer as any}>
        {dateFromElement({ readOnly: mobile })}
        {dateToElement({ readOnly: mobile })}
      </FlexBox>
      <Modal
        ref={setPopperElement as any}
        width="fit-content"
        showOverlay={mobile}
        theme="white"
        showCloseIcon={false}
        title={focusedInput === 'startDate' ? startDatePlaceholder : endDatePlaceholder}
        isOpen={focusedInput !== null}
        onClose={closeCalendar}
        positionedByPopper={!mobile}
        appendTo={appendTo}
        {...!mobile ? { ...attributes.popper } : {}}
        style={{ width: 'auto', ...!mobile ? { ...styles.popper } : {} }}
      >
        <FlexBox flexDirection="column">
          {focusedInput !== null && mobile && (
          <FlexBox backgroundColor="#e9e9e9" p="small">
            {focusedInput === 'startDate'
              ? dateFromElement({ isModal: true })
              : dateToElement({ isModal: true })}
          </FlexBox>
          )}
          <DayPickerRangeController
            key={`${calendarKey}_${focusedInput}`}
            startDate={startDateValue}
            endDate={endDateValue}
            calendarInfoPosition="bottom"
            renderCalendarInfo={() => (presets?.length ? (
              <Presets onDatesChange={onPresetClickHandler} presets={presets} customSettings={customSettings}/>
            ) : null)}
            renderNavNextButton={(props) => <NextBtn {...props}/>}
            renderNavPrevButton={(props) => <PrevBtn {...props}/>}
            focusedInput={focusedInput}
            onDatesChange={onDatesChange}
            onFocusChange={onFocusChange}
            minDate={minDate}
            isOutsideRange={isOutsideRange}
            hideKeyboardShortcutsPanel
            numberOfMonths={numberOfMonths}
            minimumNights={0}
            initialVisibleMonth={() => {
              const date = focusedInput === 'endDate' && endDateValue !== null ? endDateValue : startDateValue;
              return date !== null ? date : moment();
            }}
          />
        </FlexBox>
      </Modal>
    </>
  );
};

DateRangePicker.defaultProps = defaultProps;

export default memo(DateRangePicker);
