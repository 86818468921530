import React, { CSSProperties } from 'react';
import { useParams } from 'react-router-dom';

import { UrlParams } from '@liveroom/types';

import { Page } from '@liveroom/layout';
import { OffersContainer } from '@liveroom/containers';

type LiveRoomProps = {
  params?: any | undefined;
  isFavouritesEnabled?: boolean | undefined;
  showFooter?: boolean | undefined;
  pageStyle?: CSSProperties | undefined;
}

const LiveRoom = ({
  params = undefined,
  isFavouritesEnabled = true,
  showFooter = true,
  pageStyle = {},
}: LiveRoomProps) => {
  const routeParams = useParams<UrlParams>();

  return (
    <Page showFooter={showFooter} pageStyle={pageStyle}>
      <OffersContainer
        params={params ?? routeParams}
        isFavouritesEnabled={isFavouritesEnabled}
        autoRefreshEnabled
      />
    </Page>
  );
};
export {
  LiveRoom,
};
