import axios from 'axios';

import { CONTENT_SERVICE_URL } from '@liveroom/constants';
import * as Sentry from '@sentry/react';
import i18next from 'i18next';
import { getCurrentLanguage } from '@ess/utils';

class AffiliateConfigService {
  affiliateId: number;

  constructor(affiliateId: string) {
    this.affiliateId = parseInt(affiliateId.substring(1), 36);
  }

  /**
   * Makes api call to retrieve affiliate config.
   */
  async fetch() {
    let response = null;

    try {
      const request = await axios.post(`${CONTENT_SERVICE_URL}IbeLinkAffiliateData`, {
        Language: getCurrentLanguage(),
        IbeLinkAffiliateData: {
          AffiliateId: this.affiliateId,
        },
      });

      response = request?.data?.Sections?.IbeLinkAffiliateData?.affiliateConfig;
    } catch (error) {
      Sentry.captureException(error);
    }

    return response;
  }
}

export {
  AffiliateConfigService,
};
