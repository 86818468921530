import React from 'react';

import Drawer from '@ess/ui/Drawer';
import FlexBox from '@ess/ui/FlexBox';

export type MobileProps = {
  label: string
  isOpen: boolean
  isLoading: boolean
  children: React.ReactElement
  controlsBottom: React.ReactElement
  controlsTop: React.ReactElement
  onClose: () => void
}

const defaultProps = {
  appendTo: undefined,
};

const Mobile = ({
  label,
  isLoading,
  isOpen,
  children,
  controlsBottom,
  controlsTop,
  onClose,
}: MobileProps) => (
  <Drawer
    title={label}
    isOpen={isOpen}
    onClose={onClose}
    controls={controlsBottom}
    position="bottom"
    showOverlay
    fixHeight={!isLoading}
  >
    {controlsTop}
    <FlexBox p="small" height="100%">
      {children}
    </FlexBox>

  </Drawer>
);

Mobile.defaultProps = defaultProps;

export default Mobile;
