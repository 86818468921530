import React, { CSSProperties } from 'react';
import { useDroppable } from '@dnd-kit/core';

import FlexBox from '@ess/ui/FlexBox';

type DroppableProps = {
  id: string
  style?: CSSProperties
}

const defaultProps = {
  style: {},
};

const Droppable = ({ id, style }: DroppableProps) => {
  const { setNodeRef, isOver } = useDroppable({ id });

  return (
    <FlexBox
      ref={setNodeRef}
      alignItems="center"
      justifyContent="center"
      borderRadius={8}
      zIndex={991}
      style={{
        ...style,
        backgroundColor: isOver ? 'rgb(157 215 255 / 95%)' : 'rgb(215 239 255 / 85%)',
        transition: 'all 0.2s ease-in',
      }}
    />
  );
};

Droppable.defaultProps = defaultProps;

export default Droppable;
