import FlexBox from '@ess/ui/FlexBox';
import Loader from '@ess/ui/Loader';
import Text from '@ess/ui/Text';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { getTFGData } from '@tourop/components/OfferElements/TFGData';
import { isMobileOnly } from 'react-device-detect';

type PriceListContentProps = {
  offerData:any
  isLoading: boolean
  isBigContent?: boolean
  status: any
  minWidth?: number
}

const getAdditionalInformationList = (price: any, originalPrice: any, t:any, participantsList:any) => {
  const informationList: any[] = [];
  const { FirstPerson, Total } = price;
  const tfg = FirstPerson ? getTFGData(FirstPerson) : false;
  if (!price?.FirstPerson?.details?.TFGIncluded) {
    informationList.push({
      name: 'TFGNotIncluded',
      desc: `${t('tfg_not_included')} ${tfg?.data?.sum ? `(+${(Number(tfg?.data?.sum?.amount) * participantsList.length)} ${tfg?.data?.sum?.currency})` : ''}`,
    });
  }
  if (price?.FirstPerson?.currency !== originalPrice?.FirstPerson?.currency) {
    informationList.push({
      name: 'differentCurrencyProbablePrice',
      desc: `${t('lbl_propable_price')}: ${Total.amount} ${Total.currency}`,
    });
  }
  if (price?.FirstPerson?.currency !== originalPrice?.FirstPerson?.currency) {
    informationList.push({
      name: 'differentCurrency',
      desc: `${t('lbl_currency_different')} ( ${originalPrice?.FirstPerson?.currency} )`,
    });
  }

  if (!isEmpty(Total?.amount_old) && Total?.amount_old !== Total?.amount) {
    const priceDiff = Number(Total?.amount_old) - Number(Total?.amount);
    const displayedDiffAmount = Math.abs(priceDiff).toFixed(2);
    informationList.push({
      name: 'PriceChanged',
      desc: `${t('lbl_calc_before_price')}: ${Total?.amount_old} ${Total.currency} ${priceDiff > 0 ? t('lbl_greater_than') : t('lbl_smaller_than')} ${displayedDiffAmount} ${Total.currency}`,
    });
  }
  return informationList;
};

const PriceListContent = (
  {
    isBigContent = false,
    offerData,
    minWidth = 0,
    status,
    isLoading,
  }:PriceListContentProps,
) => {
  const { Base } = offerData;

  const { Availability, OriginalPrice, Price } = Base;

  const { Total: originalTotal, Details: originalDetails } = OriginalPrice;

  const { Total, Details } = Price;

  const { Participants: originalParticipants } = originalDetails ?? {};

  const { Participants } = Details ?? {};

  const showErrorMessage = (): boolean => !(Availability?.base === 'available' || Availability?.base === 'onrequest');

  const message = Availability?.message;

  const { isReady = false, isError = false } = status ?? offerData?.fieldStatus('Base.Price') ?? {};

  const { t } = useTranslation();

  const getDetailsList = () => {
    if (!isEmpty(originalParticipants)) {
      return originalParticipants;
    }
    return Participants;
  };

  if (showErrorMessage()) {
    return (
      <FlexBox
        m="auto"
        minHeight={isBigContent ? '160px' : 'unset'}
        padding=" 8px 4px"
        minWidth={minWidth}
        flexDirection="column"
      >
        <Text
          m="auto"
          textAlign="center"
          fontWeight="bold"
        >
          {message}
        </Text>
      </FlexBox>
    );
  }

  if (isLoading) {
    return (
      <FlexBox
        minWidth={minWidth}
        padding={isBigContent ? '8px 4px' : 'unset'}
        minHeight={isBigContent ? '160px' : 'unset'}
        flexDirection="column"
      >
        <Loader
          color="rgb(16, 44, 88)"
          m="auto auto 0 auto"
          type="dots"
        />
        <Text
          m="10px auto auto auto"
          textAlign="center"
        >
          {t('loading_options')}
        </Text>
      </FlexBox>
    );
  }

  if (isError) {
    return (
      <FlexBox
        minWidth={minWidth}
        p="small "
        width="100%"
        minHeight={isBigContent ? '160px' : 'unset'}
        justifyContent="space-between"
        alignItems="center"
        height="100%"
      >
        {message ?? t('offer_status_error')}
      </FlexBox>
    );
  }

  if (!isReady && isEmpty(getDetailsList())) {
    return (
      <FlexBox
        minWidth={minWidth}
        p="small"
        width="100%"
        minHeight={isBigContent ? '160px' : 'unset'}
        justifyContent="space-between"
        alignItems="center"
      >
        {t('offer_status_please_check')}
      </FlexBox>
    );
  }

  if (isEmpty(getDetailsList())) {
    return (
      <FlexBox
        padding={isMobileOnly ? '0 5px' : '0'}
        minWidth={minWidth}
        minHeight="160px"
        width="100%"
        height="100%"
        flexDirection="column"
      >
        <Text
          m="auto"
          fontWeight="bold"
        >
          {t('lbl_section_data_error')}
        </Text>
      </FlexBox>
    );
  }

  return (
    <FlexBox
      minWidth={minWidth}
      flexGrow="1"
      flexDirection="column"
      padding="15px 12px"
      style={{
        gap: '15px 0',
      }}
    >
      {getDetailsList().map((participant:any, index: number) => {
        const isChild = participant?.code === 'CHILD';

        return (

          <FlexBox >
            <Text
              fontSize={isMobileOnly ? '16px' : '14px'}
              marginRight="auto"
            >
              {`${t(`lbl_${participant?.code?.toLowerCase()}`)}`}
              <Text
                as="span"
                fontWeight="bold"
              >
                {isChild ? ` ( ${participant?.birthdate.split('-').reverse().join('.')} )` : ''}
              </Text>
            </Text>
            <Text
              fontSize={isMobileOnly ? '16px' : '14px'}
            >
              {participant?.amount}
              {' '}
              {participant?.currency}
            </Text>
          </FlexBox>
        );
      })}
      <FlexBox
        mt="auto"
        flexDirection="column"
        pb="12px"
        style={{
          borderBottom: '1px solid rgb(222, 222, 222)',
        }}
      >
        <FlexBox
          flexGrow="1"
        >
          <Text
            fontSize={isMobileOnly ? '16px' : '14px'}
            paddingRight="10px"
            marginRight="auto"
            fontWeight="bold"
          >
            {t('totalprice')}
          </Text>
          <FlexBox
            flexDirection="column"
          >
            <Text
              fontSize={isMobileOnly ? '16px' : '14px'}
              marginLeft="auto"
              fontWeight="bold"
            >
              {Total.currency !== originalTotal.currency ? originalTotal.amount : Total.amount}
              {' '}
              {originalTotal.currency}
            </Text>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {!isEmpty(getAdditionalInformationList(Price, OriginalPrice, t, getDetailsList())) && (
        <FlexBox
          mt="-4px"
          flexDirection="column"
        >
          <Text
            fontWeight="bold"
          >
            {t('additional_information')}
            :
          </Text>
          {getAdditionalInformationList(Price, OriginalPrice, t, getDetailsList())?.map((option:any) => (
            <Text
              mt="4px"
            >
              {option?.desc}
            </Text>
          ))}
        </FlexBox>
      )}
    </FlexBox>
  );
};
export default PriceListContent;
