import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { OptionTypeBase } from 'react-select';
import { clone, sortBy, isEmpty, has } from 'lodash-es';

import { UrlParams } from '@liveroom/types';
import { IOption, ISearchType } from '@ess/types';

import { useLiveRoomStore } from '@liveroom/hooks';

import { OfferList } from '@liveroom/components/OfferList';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Select from '@ess/ui/Form/Select';

type OffersContainerProps = {
  params?: any
  isFavouritesEnabled?: boolean
  autoRefreshEnabled?: boolean
}

const defaultProps = {
  params: undefined,
  isFavouritesEnabled: true,
  autoRefreshEnabled: false,
};

const OffersContainer = ({ params, autoRefreshEnabled, isFavouritesEnabled }: OffersContainerProps) => {
  const routeParams = useParams<UrlParams>();
  const { t } = useTranslation();
  const [sortKey, setSortKey] = useState('DEFAULT');
  const { fetchOffers, offers } = useLiveRoomStore((state) => ({
    fetchOffers: state.fetchOffers,
    offers: state.offers,
  }));
  const [sortedOffers, setSortedOffers] = useState<any>(null);

  const getOptions = () => {
    const options: IOption[] = [];

    options.push({
      label: t('opt_Order-in-the-basket'),
      value: 'DEFAULT',
    });
    options.push({
      label: t('opt_price'),
      value: 'PRICE',
    });
    options.push({
      label: t('opt_hotel'),
      value: 'HOTEL',
    });
    options.push({
      label: t('opt_country'),
      value: 'COUNTRY',
    });
    options.push({
      label: t('opt_city'),
      value: 'CITY',
    });
    options.push({
      label: t('opt_transport'),
      value: 'TRANSPORT',
    });
    options.push({
      label: t('opt_operator'),
      value: 'OPERATOR',
    });

    return options;
  };

  const changeHandler = (selectedOption: OptionTypeBase) => {
    const type = selectedOption.value;
    setSortKey(type);
  };

  useEffect(() => {
    fetchOffers({
      params: params ?? routeParams,
      config: {
        autoRefreshEnabled: !!autoRefreshEnabled,
      },
      isFirstRequest: true,
    });
  }, []);

  useEffect(() => {
    setSortedOffers(offers);
  }, [offers]);

  useEffect(() => {
    const sortoffers = clone(offers);
    sortoffers.items.forEach((element, index) => {
      if (has(element.offer.Transport, 'Flight')) {
        sortoffers.items[index].offer.Transport.Mode = 'Aeroplane';
      } else if (has(element.offer.Transport, 'Bus')) {
        sortoffers.items[index].offer.Transport.Mode = 'Coach';
      } else if (isEmpty(element.offer.Transport)) {
        sortoffers.items[index].offer.Transport.Mode = 'Own Trasport';
      }
    });
    let sortOder: string = '';
    switch (sortKey) {
      case 'PRICE':
        sortOder = 'offer.Base.Price.Total.amount';
        break;
      case 'HOTEL':
        sortOder = 'offer.Accommodation.XCode.Name';
        break;
      case 'COUNTRY':
        sortOder = 'offer.Accommodation.XCountry.Name';
        break;
      case 'CITY':
        sortOder = 'offer.Accommodation.XCity.Name';
        break;
      case 'TRANSPORT':
        sortOder = 'offer.Transport.Mode';
        break;
      case 'OPERATOR':
        sortOder = 'offer.Base.OperatorDesc';
        break;
      default:
        break;
    }
    if (sortOder.length > 0) {
      sortoffers.items = sortBy(offers.items, sortOder);
    }
    setSortedOffers(sortoffers);
  }, [sortKey]);

  return (
    <FlexBox width="100%" flexDirection="column">
      {params.compareOffers && (
        <FlexBox
          width="100%"
          justifyContent={{
            xxs: 'center',
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'flex-start',
          }}
          marginBottom="7px"
        >
          <FlexBox>
            <Text
              py="small"
              lineHeight="34px"
              fontSize="24px"
              textAlign="left"
            >
              {`${t('compare-offers')}: ${sortedOffers?.name ?? ''}`}
            </Text>
          </FlexBox>
          <FlexBox width="260px" marginLeft="auto">
            <Text
              px="large"
              py="small"
              lineHeight="36px"
              fontSize="13px"
              textAlign="right"
              paddingLeft="10px"
            >
              {`${t('sort-by')}: `}
            </Text>
            <FlexBox maxWidth="180px" width="100%" paddingTop="10px" paddingBottom="10px">
              <Select
                name="SortBy"
                options={getOptions()}
                value={sortKey}
                isClearable={false}
                isSearchable={false}
                onChange={changeHandler}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
      <OfferList
        offers={sortedOffers}
        isFavouritesEnabled={isFavouritesEnabled}
      />
    </FlexBox>
  );
};

OffersContainer.defaultProps = defaultProps;

export {
  OffersContainer,
};
