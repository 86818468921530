import React from 'react';
import { has } from 'lodash-es';
import { SpaceProps } from 'styled-system';

import { IDictionary } from '@ess/types';

import OvalLoader from './OvalLoder';
import DotsLoader from './DotsLoader';

const DEFAULT_LOADER = 'oval';
const LOADER_TYPES: IDictionary<(props: any) => React.ReactElement> = {
  oval: (props) => <OvalLoader {...props}/>,
  dots: (props) => <DotsLoader {...props}/>,
};

type LoaderTypesEnum = 'oval' | 'dots';

type LoaderProps = {
  type?: LoaderTypesEnum,
  color?: string,
  size?: string;
} & SpaceProps;

const Loader = ({
  type = DEFAULT_LOADER as LoaderTypesEnum,
  color = '#102c58',
  size = '20px',
  ...props
}: LoaderProps) => (
  <>
    {type && has(LOADER_TYPES, type)
      ? (LOADER_TYPES[type]({
        type, size, color, ...props,
      }))
      : (LOADER_TYPES[DEFAULT_LOADER]({
        type, size, color, ...props,
      }))}
  </>
);

export default Loader;
