// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {
  forwardRef, useEffect, useImperativeHandle, useRef,
} from 'react';
import { SwiperProps } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import { register } from 'swiper/element/bundle';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';

register();

SwiperCore.use([Navigation, Pagination]);

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'swiper-container': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & SwiperProps,
        HTMLElement
      >;
    }
  }
}

type SliderProp = {
  children: React.ReactNode
  settings?: SwiperProps
  onClick?: () => void
  style?: React.CSSProperties
  onAfterInit?: (swiper: any) => void
  onDragEnter?: () => void
  onActiveIndexChange?: (index: number) => void
  options?: any[]
}

const defaultStyles = {
  width: '100%',
  height: '100%',
};

const defaultSettings = {
  lazy: true,
  keyboard: false,
  slidesPerView: 'auto',
  injectStyles: [
    `
        .swiper-slide {
          width: auto !important;
          z-index: 10;
        }

        .swiper-pagination-bullet {
          width: 10px;
          height: 10px;
          opacity: 0.4;
        }

        .swiper-pagination-bullet-active {
          background: #fff;
          opacity: 1;
        }

        .swiper-button-next,
        .swiper-button-prev {
          width: 36px;
          height: 36px;
          border-radius: 100%;
          background-color: #fff;

          svg {
            width: 8px;
            color: #000;
          }
        }

        .swiper-button-disabled {
          display: none;
        }
      `,
  ],
};

const SwiperContainer = forwardRef<SwiperCore, SliderProp>(({
  children,
  onClick = undefined,
  onDragEnter = undefined,
  onAfterInit = undefined,
  onActiveIndexChange = undefined,
  settings = defaultSettings,
  options = [],
  style = {},
}, ref) => {
  const swiperRef = useRef<any>(null);
  const swiperInnerState = useRef<any>({});
  const sliderOptions = { ...defaultSettings, ...settings };

  useImperativeHandle(ref, () => swiperRef.current);

  const initializeSwiper = () => {
    Object.assign(swiperRef?.current, sliderOptions);
    swiperRef?.current.initialize();

    if (onAfterInit) {
      onAfterInit(swiperRef?.current?.swiper);
    }
  };

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  const onDragEnterHandler = () => {
    if (onDragEnter) {
      onDragEnter();
    }
  };

  useEffect(() => {
    initializeSwiper();
    swiperRef?.current?.swiper?.on('activeIndexChange', (swiper) => {
      if (onActiveIndexChange) {
        onActiveIndexChange(swiper);
      }
    });
  }, []);

  useEffect(() => {
    initializeSwiper();
  }, [children]);

  useEffect(() => {
    if (swiperRef?.current?.swiper && swiperInnerState.current !== options) {
      swiperRef.current.swiper.updateSlides();
      swiperRef.current.swiper.updateSlidesProgress();
      swiperInnerState.current = options;
    }
  }, [options]);

  return (
    <>
      <swiper-container
        ref={swiperRef}
        init="false"
        onActiveIndexChange={onActiveIndexChange}
        onDragEnterCapture={onDragEnterHandler}
        onTouchStart={onClickHandler}
        style={{
          ...defaultStyles,
          ...style,
        }}
      >
        {children}
      </swiper-container>
    </>
  );
});

export {
  SwiperContainer,
};
