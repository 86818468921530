import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { includes, isArray, toLower } from 'lodash-es';

import { IDictionary } from '@ess/types';

import { MX_ANSWER_FAIL, MX_ANSWER_SUCCESS } from '@ess/constants/api';

import {
  encodeOfferId,
  getHotelExtra,
  getOfferExtraKey,
  getParticipantsSearchSchema,
  getOfferHash,
  showToast,
  TOAST_ERROR,
  TOAST_SUCCESS,
} from '@ess/utils';

import { BasketContext } from '@ess/context/BasketContext';
import { AppConfigContext } from '@ess/context/AppConfigContext';

import { useSearchConditionsParticipants } from '@ess/v5-data-provider/mwsfunc/hooks';

import { useSelector } from '@ess/store/core';

import { BASKET_STORAGE_SECTION } from '@basket/constants';

import useOfferContentService, { Sections } from './useOfferContentService';

export const BASKET_ACTION_ADD = 'add';
export const BASKET_ACTION_DELETE = 'delete';

type BasketActionType = 'add' | 'delete';

type RequestArgs = {
  actionType: BasketActionType,
  offer: any
}

const TRANSLATION_KEYS: IDictionary<any> = {
  add: {
    success: 'basket_offer_added',
    fail: 'basket_offer_add_fail',
  },
  delete: {
    success: 'basket_offer_deleted',
    fail: 'basket_offer_delete_fail',
  },
};

const useBasket = () => {
  const { t } = useTranslation();
  const { state: SFContext } = useContext(AppConfigContext);
  const { state: BasketState, dispatch } = useContext(BasketContext);
  const [isLoading, setIsLoading] = useState(false);
  const participantList: any = useSearchConditionsParticipants();
  const sfProtoHash = useSelector((state) => state.searchForm.protoHash);
  const { basketItems, basketId } = BasketState;
  const { maxBasketItems, type } = SFContext;
  const {
    results, errors, fetchSections, requestParams,
  } = useOfferContentService();

  const getExtraHotel = (offer: any) => {
    const hasSupplementary = offer?.Accommodation?.Supplementary;
    const { extraHotel = null } = encodeOfferId(offer.Base.OfferId) ?? {};
    const supplementaryData = hasSupplementary
      ? getHotelExtra(getOfferExtraKey(offer.Base.OfferId).key)
      : null;

    if (extraHotel?.id) {
      return extraHotel;
    }

    return supplementaryData;
  };

  /**
   * Returns item hash.
   * @param item
   */
  const getItemHash = (item: any) => {
    const offer = item?.offer ? item.offer : item;
    const extraHotelData = getExtraHotel(offer);

    const data = {
      offerId: offer.Base.OfferId,
      operator: offer.Base.Operator,
      participantList: getParticipantsSearchSchema(participantList),
      ...extraHotelData ? {
        extraHotel: {
          code: extraHotelData.code,
          id: extraHotelData.id,
        },
      } : {},
    };

    return getOfferHash(data);
  };

  /**
   * Returns items hash array.
   * @param offer
   */
  const getItems = (offer: any) => {
    const offersHashes: any[] = [];
    const isSingleOffer = !isArray(offer);
    const items = isSingleOffer ? [offer] : offer;

    items.map((item) => {
      offersHashes.push({
        itemHash: getItemHash(item),
        offerHash: item.Base.OfferId,
      });
    });

    return {
      [isSingleOffer ? 'item' : 'items']: isSingleOffer ? offersHashes[0] : offersHashes,
    };
  };

  useEffect(() => {
    if (!requestParams[Sections.Basket]) {
      return;
    }

    const actionType = requestParams[Sections.Basket].Action;

    if (errors[Sections.Basket] || !results || !results.Sections[Sections.Basket]
      || results.Sections[Sections.Basket].Status !== MX_ANSWER_SUCCESS) {
      showToast(TOAST_ERROR, t(TRANSLATION_KEYS[actionType][toLower(MX_ANSWER_FAIL)]));

      return;
    }

    dispatch({
      type: 'SET_BASKET',
      payload: {
        basketId,
        basketItems: actionType === BASKET_ACTION_ADD
          ? [...basketItems, requestParams[Sections.Basket].OfferId]
          : basketItems.filter((item) => item !== requestParams[Sections.Basket].OfferId),
      },
    });

    if (typeof window.MXSmallBasket !== 'undefined' && basketId) {
      window.MXSmallBasket.reload(basketId);
    }

    setIsLoading(false);
    showToast(TOAST_SUCCESS, t(TRANSLATION_KEYS[actionType][toLower(MX_ANSWER_SUCCESS)]));
  }, [errors, results]);

  /**
   * Basket request handler.
   * @param actionType
   * @param data
   */
  const basketRequest = async ({ actionType, offer }: RequestArgs) => {
    if (!window.GlobalVariables.isBasketGoOn && (basketItems.length) >= maxBasketItems) {
      showToast(TOAST_ERROR, t('basket_max_items_message'));
      return;
    }

    const items: any = getItems(offer);
    const singleOffer = isArray(offer) ? offer[0] : offer;

    setIsLoading(true);

    if (window?.GlobalVariables?.isBasketGoOn) {
      if (actionType === 'add') {
        try {
          await window[BASKET_STORAGE_SECTION].addItem({
            itemType: 'merlin',
            item: [{
              hash: items.item.offerHash,
            }],
            search: {
              form: type,
              hash: sfProtoHash,
            },
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      } else {
        try {
          const basketItems = window.BasketReact.basket.items.data ?? [];
          const rowIds = basketItems?.filter((item: any) => item?.offer?.Base?.OfferId === (items?.item?.offerHash ?? items?.items?.[0]?.offerHash)).map((option: any) => option.rowid) ?? [];
          await window[BASKET_STORAGE_SECTION].deleteItem(rowIds);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      fetchSections({
        sections: [Sections.Basket],
        params: {
          Currency: singleOffer.Base.Price.FirstPerson.currency,
          Basket: {
            Action: actionType,
            ...actionType === BASKET_ACTION_DELETE ? {
              Id: basketId,
              OfferId: singleOffer.Base.OfferId,
              ItemHash: items?.item?.itemHash,
            } : {
              Id: basketId,
              SearchType: type,
              SearchHash: sfProtoHash,
              OfferId: singleOffer.Base.OfferId,
              ItemHash: items?.item?.itemHash,
            },
          },
        },
      }).then(() => {
        setIsLoading(false);
      });
    }
  };

  /**
   * Indicates if hash exists in basket.
   * @param offerHash
   */
  const isInBasket = useCallback((offer: any) => includes(basketItems, offer.Base.OfferId), [basketItems]);

  return {
    basketRequest,
    isInBasket,
    basketId,
    isLoading,
  };
};

export default useBasket;
