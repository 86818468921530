import React, { useCallback } from 'react';

import { ILiveRoomOffers } from '@liveroom/types';

import useFavourites from '@ess/hooks/useFavourites';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';

import { Offer } from '@ess/ui/Offer';
import OfferListSkeleton from './OfferListSkeleton';

import { Styled } from './OfferList.styles';

type OfferListProps = {
  offers: ILiveRoomOffers
  isFavouritesEnabled?: boolean
}

const defaultProps = {
  isFavouritesEnabled: true,
};

const OfferList = ({ offers, isFavouritesEnabled }: OfferListProps) => {
  const {
    favourites, add, remove,
  } = useFavourites<number>('LiveRoom', false);

  const onClick = useCallback((rowId: number) => false, []);

  const onFavouriteClick = useCallback((rowId: number) => (
    !favourites.includes(rowId) ? add(rowId) : remove(rowId)
  ), [favourites]);

  if (offers?.error) {
    return (
      <FlexBox
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontSize="24px">{`error: ${offers.error}`}</Text>
      </FlexBox>
    );
  }

  if (offers?.isLoading) {
    return (
      <OfferListSkeleton/>
    );
  }

  return (
    <Styled.OffersList__Grid>
      {offers?.items?.map((item: any) => (item?.offer ? (
        <Offer
          key={item.rowid}
          data={item}
          onClick={onClick}
          onFavouriteClick={onFavouriteClick}
          isFavourite={favourites.includes(item.rowid)}
          isFavouritesEnabled={isFavouritesEnabled}
        />
      ) : null))}
    </Styled.OffersList__Grid>
  );
};

OfferList.defaultProps = defaultProps;

export {
  OfferList,
};
