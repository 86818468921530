import styled from 'styled-components';
import { space } from 'styled-system';

const Loader = styled.svg<{ size: string, color: string }>`
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    stroke: ${(props) => props.color};
    fill: ${(props) => props.color};
    ${space}
`;

export const Styled = {
  Loader,
};
