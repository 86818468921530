import { getFirstLetters } from '@ess/utils';

import { forwardRef } from 'react';
import { Styled } from './Avatar.styles';

type AvatarProps = {
  img?: string;
  alt: string;
  size?: number | string
  onClick?: () => void
}

const defaultProps = {
  size: 40,
  img: undefined,
  onClick: undefined,
};

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(({
  img, alt, size, onClick,
}, ref) => (
  <Styled.Avatar ref={ref} size={size} onClick={onClick}>
    {img ? (
      <Styled.Avatar__Img src={img} alt={alt}/>
    ) : (
      <Styled.Avatar__Text>
        {getFirstLetters(alt, 2).toUpperCase()}
      </Styled.Avatar__Text>
    )}
  </Styled.Avatar>
));

Avatar.defaultProps = defaultProps;

export default Avatar;
