import React from 'react';

import { Styled } from '../Tabs.styles';

type TabPanelProps = {
  children: React.ReactNode
  hidden?: boolean
  value: any
};

const defaultProps = {
  hidden: true,
};

const TabPanel = ({
  children, hidden, value,
}: TabPanelProps) => (
  <Styled.TabPanel
    id={`tab-panel-${value}`}
    role="tabpanel"
    {...(hidden ? { hidden } : {})}
  >
    {children}
  </Styled.TabPanel>
);

TabPanel.defaultProps = defaultProps;

export default TabPanel;
