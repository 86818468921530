import React, { CSSProperties, useEffect, useState } from 'react';

import axios from 'axios';
import { faImageSlash } from '@fortawesome/pro-light-svg-icons';
import { Styled } from './Image.styles';

type ImageProps = {
  src: string,
  alt?: string,
  width?: (number | string),
  iconHeight?: number
  iconWidth?: number
  height?: number | string,
  objectFit?: 'cover' | 'contain'
  onClick?: (event: React.MouseEvent) => void
  cursor?: string
  style?: CSSProperties
  loading?: any
  children?: any;
  imageValidationEnabled?: boolean;
  onError?: () => void
};

enum imageStatusTypes {
  Error = 'error',
  Loading = 'loading',
  Ready ='Ready'
}

const checkIfImageIsValid = async (src: string) => new Promise((resolve) => {
  const img = new Image();
  img.onload = () => resolve(imageStatusTypes.Ready);
  img.onerror = () => resolve(imageStatusTypes.Error);
  img.src = src;
});

const Img = ({
  src,
  alt,
  imageValidationEnabled = true,
  iconHeight = 40,
  iconWidth = 40,
  width = 0,
  height = 0,
  onError = () => false,
  onClick = () => false,
  objectFit = 'cover',
  cursor = 'default',
  style = {},
  children,
  ...props
}: ImageProps) => {
  const [imageStatus, setImageStatus] = useState<imageStatusTypes>(imageStatusTypes.Loading);

  useEffect(() => {
    checkIfImageIsValid(src).then((state: any) => {
      if (state === 'error') {
        if (onError) {
          onError();
        }
      }
      setImageStatus(state);
    });
  }, []);

  if (imageValidationEnabled && imageStatus === 'error') {
    return (
      <Styled.Not_Valid_Image
        onClick={onClick}
        height={height}
        cursor={cursor}
        objectFit={objectFit}
        width={width}
        style={style}
      >
        <Styled.Not__Valid__Image__Icon
          iconHeight={iconHeight}
          iconWidth={iconWidth}
          icon={faImageSlash}
        />
      </Styled.Not_Valid_Image>
    );
  }

  return (
    <Styled.Image
      onClick={onClick}
      src={src}
      alt={alt}
      width={width}
      height={height}
      objectFit={objectFit}
      cursor={cursor}
      style={style}
      {...props}
    />
  );
};

export default Img;
