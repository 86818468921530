import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@ess/ui/Button';
import { Modal } from '@ess/ui/Modal/ModalsTypes';
import TextInput from '@ess/ui/Form/TextInput';
import FlexBox from '@ess/ui/FlexBox';

type EditNameModalProps = {
  name?: string
  onApply: (name: string) => void
  onClose: () => void
  title: string
  placeholder: string
}

const defaultProps = {
  name: '',
};

const EditNameModal = ({
  onApply,
  onClose,
  name,
  title,
  placeholder,
}: EditNameModalProps) => {
  const { t } = useTranslation();
  const [itemName, setItemName] = useState('');

  const onApplyHandler = () => {
    onApply(itemName);
    onClose();
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItemName(event.target.value);
  };

  useEffect(() => {
    setItemName(name ?? '');
  }, [name]);

  return (
    <Modal
      title={title}
      isOpen
      style={{
        zIndex: 999999,
      }}
      onClose={onClose}
      controls={(
        <FlexBox p="small" width="100%" alignItems="center" justifyContent="flex-end">
          <Button
            variant="secondary"
            label={t('lbl_cancel')}
            size="small"
            mr="small"
            width="90px"
            onClick={onClose}
          />
          <Button
            variant="primary"
            label={t('lbl_apply')}
            size="small"
            width="90px"
            onClick={onApplyHandler}
            disabled={!itemName}
          />
        </FlexBox>
      )}
    >
      <FlexBox p="small" width="100%">
        <TextInput
          value={itemName}
          autoFocus
          placeholder={placeholder}
          isClearable
          onClear={() => setItemName('')}
          onChange={onChangeHandler}
          autoSelect
        />
      </FlexBox>
    </Modal>
  );
};

EditNameModal.defaultProps = defaultProps;

export default memo(EditNameModal);
