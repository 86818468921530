import React from 'react';
import { useTranslation } from 'react-i18next';
import { indexOf, partition } from 'lodash-es';
import { useToggle } from 'react-use';
import {
  autoPlacement,
  FloatingPortal,
  safePolygon,
  size,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react';

import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Chip, { ChipGroup } from '@ess/ui/Chip';
import { Card } from '@ess/ui/Card';

import { ScrollBar } from '@ess/components/ScrollBar';

type AttributesProps = {
  attributes: string[]
}

const Attributes = ({ attributes }: AttributesProps) => {
  const { t } = useTranslation();
  const [featured, rest] = partition(attributes, (item) => indexOf(attributes, item) < 2);
  const [isOpen, setIsOpen] = useToggle(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      autoPlacement({
        allowedPlacements: ['bottom-start', 'top-start'],
      }),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
      }),
    ],
  });

  const hover = useHover(context, {
    move: true,
    delay: { open: 75 },
    handleClose: safePolygon({ blockPointerEvents: true }),
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
  ]);

  return (
    <ChipGroup>
      {featured.map((item, index) => (
        <Chip
          key={`attributes-${index}`}
          variant="border"
          label={t(item)}
        />
      ))}

      {isOpen && (
        <FloatingPortal>
          <Card
            ref={refs.setFloating}
            width={250}
            style={{
              ...floatingStyles,
              zIndex: 99999,
            }}
            {...getFloatingProps()}
          >
            <ScrollBar>
              <FlexBox p="small" flexDirection="column">
                {rest.map((item, index) => (
                  <FlexBox
                    key={`rest-attributes-${index}`}
                    height={30}
                    alignItems="center"
                  >
                    <Text>{t(item)}</Text>
                  </FlexBox>
                ))}
              </FlexBox>
            </ScrollBar>

          </Card>
        </FloatingPortal>
      )}

      {rest.length > 0 && (
        <FlexBox
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          <Chip variant="border" label={`+${rest.length}`}/>
        </FlexBox>
      )}
    </ChipGroup>
  );
};

export {
  Attributes,
};
