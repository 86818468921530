import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '@ess/auth-provider';

import { AuthenticatedContainer } from '@merlin/containers';

const getReferrer = (location: any) => {
  const { pathname, search } = location;

  if (pathname && !pathname.includes('login') && pathname !== '/') {
    return {
      pathname,
      search: search || '',
    };
  }

  return undefined;
};

const ProtectedRoute = () => {
  const isAuthenticated = useAuth((state) => state.isAuthenticated);
  const location = useLocation();
  const referrer = getReferrer(location) ?? '';

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ referrer }}/>;
  }

  return (
    <AuthenticatedContainer>
      <Outlet />
    </AuthenticatedContainer>
  );
};

export { ProtectedRoute };
