import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-solid-svg-icons';

import { useFetchImages, ImagesSizes } from '@ess/hooks/useFetchImages';

import { SwiperContainer, SwiperSlide } from '@ess/ui/Swiper';
import Loader from '@ess/ui/Loader';
import Skeleton from '@ess/ui/Skeleton';
import FlexBox from '@ess/ui/FlexBox';

import Img from '@ess/ui/Image';
import { NoPrint, PrintOnly } from '@ess/react-print';
import { NavigationArrow } from './NavigationArrow';

import { Styled } from './ImageSlider.styles';

type ImageSliderProps = {
  image?: string | undefined
  operator: string
  xCode?: number | undefined
  hotelCode: string
  imageSize?: ImagesSizes
  height?: number
  width?: number
  onClick?: (event?: React.MouseEvent) => void
  serviceUrl?: string
  lazyFetch?: boolean
  arrowStyle?: string
}

const ImageSlider = ({
  image = undefined,
  xCode,
  height = undefined,
  width = undefined,
  operator,
  hotelCode,
  imageSize = ImagesSizes.Full,
  lazyFetch = true,
  serviceUrl = undefined,
  onClick = () => false,
  arrowStyle = 'regular',
}: ImageSliderProps) => {
  const swiperRef = useRef<any>(null);
  const goToNextSlideAfterFetch = useRef(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    isLoading,
    isReady,
    data,
    fetch,
  } = useFetchImages({
    lazy: lazyFetch,
    initialData: image ? [image] : [],
    serviceUrl,
    imageSize,
    hotelCode,
    xCode,
    operator,
  });

  /**
   * Next image handler.
   * @param event
   */
  const nextImageHandler = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();

    if (!data.length) {
      goToNextSlideAfterFetch.current = true;
      fetch();
    } else {
      swiperRef.current.swiper.slideNext();
    }
  }, [data]);

  /**
   * Previous image handler.
   * @param event
   */
  const previousImageHandler = (event: React.MouseEvent) => {
    event.stopPropagation();

    swiperRef.current.swiper.slidePrev();
  };

  const fetchImagesIfNeeded = () => {
    if (!data.length) {
      fetch();
    }
  };

  const onActiveIndexChange = (swiper: any) => {
    setCurrentIndex(swiper?.activeIndex ?? 0);
  };

  /**
   * Slider click handler.
   * @param event
   */
  const onSliderClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (onClick) {
      onClick(event);
    }
  };

  const onImageError = () => {
    setIsDisabled(true);
  };

  useEffect(() => {
    let timeout: any;

    if (isReady && data.length && goToNextSlideAfterFetch.current) {
      timeout = setTimeout(() => {
        swiperRef.current.swiper.slideTo(1);
        goToNextSlideAfterFetch.current = false;
      }, 200);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isReady, data]);

  return (
    <Styled.ImageSlider
      height={height}
      width={width}
      onClick={onSliderClick}
    >
      {data && (
        <PrintOnly>
          <FlexBox position="absolute" width="100%" height="100%">
            <Img
              width="100%"
              height="100%"
              src={image ?? ''}
            />
          </FlexBox>
        </PrintOnly>
      )}
      {!image && (
        <FlexBox position="absolute" width="100%" height="100%">
          <Skeleton width="100%" height="100%">
            <FontAwesomeIcon icon={faImage} color="#bfbfbf" size="5x"/>
          </Skeleton>
        </FlexBox>
      )}
      <SwiperContainer
        ref={swiperRef}
        onDragEnter={fetchImagesIfNeeded}
        onActiveIndexChange={onActiveIndexChange}
      >
        {isReady && data.length ? data?.map((image) => (
          <SwiperSlide key={image} style={{ width: '100%' }}>
            <Styled.ImageSlider__Item
              alt={operator}
              loading="lazy"
              src={image}
              onError={onImageError}
            />
          </SwiperSlide>
        )) : image ? (
          <SwiperSlide>
            <Styled.ImageSlider__Item
              alt={operator}
              onError={onImageError}
              loading="lazy"
              src={image}
            />
          </SwiperSlide>
        ) : null}
      </SwiperContainer>

      <>
        {isLoading && image && (
          <Styled.ImageSlider__Overlay>
            <Loader size="22px" type="dots" color="white"/>
          </Styled.ImageSlider__Overlay>
        )}

        {(!isReady || (isReady && data.length > 0)) && !isDisabled && (
        <NoPrint>
          <Styled.ImageSlider__Controls>
            {currentIndex > 0 && (
            <NavigationArrow arrowStyle={arrowStyle as string} direction="left" onClick={previousImageHandler}/>
            )}
            {(!isReady || currentIndex < data.length - 1) && (
            <NavigationArrow arrowStyle={arrowStyle as string} direction="right" onClick={nextImageHandler}/>
            )}
          </Styled.ImageSlider__Controls>
        </NoPrint>
        )}
      </>
    </Styled.ImageSlider>
  );
};
export {
  ImageSlider,
};
