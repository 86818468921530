import React, { memo, useContext } from 'react';
import {
  has, isEmpty, toNumber,
} from 'lodash-es';
import { ColorProps } from 'styled-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/pro-solid-svg-icons';

import { IPrice } from '@ess/types';

import { MIN_PRICE_DIFF_TO_SHOW_ARROW } from '@ess/constants/offer';

import { getPriceDifference } from '@ess/utils';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import { Styled } from './Price.styles';

type PriceProps = {
  price: IPrice | undefined
  showCurrency?: boolean
  isFixed?: boolean
  color?: string | number
  isSelected?: boolean
  showArrow?: boolean
} & ColorProps;

const defaultProps = {
  color: 'textColor',
  showCurrency: false,
  isFixed: true,
  showArrow: false,
  isSelected: false,
};

const Price = ({
  price,
  color,
  showCurrency,
  showArrow,
  isFixed,
  isSelected,
}: PriceProps) => {
  const { state: AppConfig } = useContext(AppConfigContext);
  // @ts-ignore
  const { amount, currency, amount_old } = price;
  const { difference = 0 } = amount_old ? getPriceDifference(amount, amount_old) : {};
  const { minPriceChangeShow } = AppConfig;

  const minPriceToShowArrow = !isEmpty(minPriceChangeShow) && has(minPriceChangeShow, currency)
    ? minPriceChangeShow[currency]
    : MIN_PRICE_DIFF_TO_SHOW_ARROW;

  const showPriceChangeArrow = showArrow && Math.abs(Math.round(toNumber(difference))) > minPriceToShowArrow;

  return (
    <>
      {toNumber(amount) > 0 && (
        <Styled.Price color={color as string}>
          <Styled.Price__Amount>{isFixed ? Math.round(Number(amount)) : amount}</Styled.Price__Amount>
          {showPriceChangeArrow && (
          <Styled.Price__Arrow>
            {(toNumber(amount_old) < toNumber(amount))
              ? <FontAwesomeIcon color={isSelected ? 'white' : 'red'} icon={faLongArrowAltUp}/>
              : <FontAwesomeIcon color={isSelected ? 'white' : 'green'} icon={faLongArrowAltDown}/>}
          </Styled.Price__Arrow>
          )}
          {showCurrency && <Styled.Price__Currency>{currency}</Styled.Price__Currency>}
        </Styled.Price>
      )}
    </>
  );
};

Price.defaultProps = defaultProps;

export default Price;
