import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isUndefined } from 'lodash-es';

import useOnClickOutside from '@ess/hooks/useOnClickOutside';
import { FreePlacesResponse } from '@ess/hooks/useFreePlaces';

import { Button } from '@ess/ui/Button';
import Modal from '@ess/ui/Modal';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Loader from '@ess/ui/Loader';

type AvailabilityModalProps = {
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  offerAjaxAnswer: FreePlacesResponse
  showFlightPlaces: boolean
  showHotelPlaces: boolean
}

const AvailabilityModal = ({
  isOpen,
  onClose,
  offerAjaxAnswer,
  showFlightPlaces,
  showHotelPlaces,
  isLoading,
}: AvailabilityModalProps) => {
  const { t } = useTranslation();
  const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);
  const isOfferOnRequest = () => (offerAjaxAnswer.offerStatus === 'onrequest');

  useOnClickOutside(modalElement, onClose);

  return (
    <Modal
      ref={setModalElement as React.Ref<HTMLDivElement>}
      title={t('lbl_menu_places')}
      isOpen={isOpen}
      onClose={onClose}
      theme="white"
      controls={(
        <FlexBox alignItems="center" justifyContent="flex-end" px="small" width="100%">
          <Button
            variant="primary"
            label={t('ok')}
            size="small"
            width="80px"
            onClick={onClose}
          />
        </FlexBox>
      )}
    >
      {isLoading || (isEmpty(offerAjaxAnswer) || isUndefined(offerAjaxAnswer)) ? (
        <FlexBox height="80px" alignItems="center" justifyContent="center">
          <Loader color="navy" size="28px"/>
        </FlexBox>
      ) : (
        <>
          {['notavailable', 'unknown'].includes(offerAjaxAnswer?.offerStatus) || (!showFlightPlaces && !showHotelPlaces) ? (
            <FlexBox p="small" width="100%">
              {offerAjaxAnswer?.statusMessage ?? t('lbl_no_transport_info')}
            </FlexBox>
          ) : (
            <>
              {(!!offerAjaxAnswer.transport.length) && showFlightPlaces && (
                <FlexBox
                  backgroundColor="backgroundSecondary"
                  p="small"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text fontWeight="bold">{t('lbl_free_places_transport')}</Text>
                </FlexBox>
              )}
              {offerAjaxAnswer.transport.map((fp, index) => (
                <FlexBox
                  key={`transport_item_${index}`}
                  p="small"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <>
                    <Text>
                      {fp.Code}
                    </Text>
                    <Text fontWeight="bold">
                      {isOfferOnRequest() ? 'RQ' : fp.Free}
                    </Text>
                  </>
                </FlexBox>
              ))}
              {(!!offerAjaxAnswer.hotel.length) && showHotelPlaces && (
                <>
                  <FlexBox
                    backgroundColor="backgroundSecondary"
                    p="small"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontWeight="bold">
                      {t('lbl_free_places_hotel')}
                    </Text>
                  </FlexBox>
                  <>
                    {offerAjaxAnswer.hotel.map((fp, index) => (
                      <FlexBox
                        key={`hotel_item_${index}`}
                        p="small"
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <>
                          <Text>
                            {fp.Code}
                          </Text>
                          <Text fontWeight="bold">
                            {isOfferOnRequest() ? 'RQ' : fp.Free}
                          </Text>
                        </>
                      </FlexBox>
                    ))}
                  </>
                </>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default AvailabilityModal;
