import React, {
  RefObject, useLayoutEffect, useState,
} from 'react';
import { createPortal } from 'react-dom';

import { theme } from '@tourop/config/theme';

import useElementSize from '@ess/hooks/useElementSize';
import usePopperPositioning from '@ess/hooks/usePopperPositioning';
import useOnClickOutside from '@ess/hooks/useOnClickOutside';

import { Styled } from '@tourop/components/MultiSelectV2/MultiSelectV2.styles';

export type DropdownProps = {
  isOpen: boolean
  children: React.ReactElement
  headerElement?: React.ReactNode
  targetElement: RefObject<any>
  controlsTop: React.ReactElement
  controlsBottom: React.ReactElement
  onClose: () => void
  appendTo?: any
  maxHeight: number
  width: number
}

const defaultProps = {
  appendTo: undefined,
  headerElement: undefined,
};

const Dropdown = ({
  isOpen,
  children,
  targetElement,
  headerElement,
  controlsTop,
  controlsBottom,
  onClose,
  appendTo,
  maxHeight,
  width,
}: DropdownProps) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { size: { width: inputWidth }, forceUpdate } = useElementSize(targetElement);

  const { styles, attributes } = usePopperPositioning({
    targetElementRef: targetElement,
    popperElementRef: popperElement,
    applyMaxSizeEnabled: true,
    zIndex: theme.zIndex.modal + 1,
    padding: 15,
  });

  useOnClickOutside(popperElement, onClose);

  useLayoutEffect(() => {
    if (isOpen) {
      forceUpdate();
    }
  }, [isOpen]);

  return (
    <>
      {createPortal(
        <>
          {isOpen && (
            <Styled.MultiSelect__DropDown__Container
              ref={setPopperElement}
              width={width && width > inputWidth ? width : inputWidth}
              style={styles.popper}
              {...attributes.popper}
            >
              {(headerElement || controlsTop) && (
                <Styled.MultiSelect__DropDown__Header>
                  {headerElement}
                  {controlsTop}
                </Styled.MultiSelect__DropDown__Header>
              )}
              <Styled.MultiSelect__DropDown maxHeight={maxHeight as number}>
                {children}
              </Styled.MultiSelect__DropDown>
              {controlsBottom}
            </Styled.MultiSelect__DropDown__Container>
          )}
        </>,
        (!appendTo ? document.querySelector('body') : appendTo) as HTMLElement,
      )}
    </>
  );
};

Dropdown.defaultProps = defaultProps;

export default Dropdown;
