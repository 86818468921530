import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';
import { useDebouncedCallback } from 'use-debounce';
import { DayPickerSingleDateController } from 'react-dates';
import { isMobileOnly } from 'react-device-detect';
import { trim } from 'lodash-es';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { FieldError } from '@ess/types';

import { DATE_DISPLAY_FORMAT, DATE_REQUEST_FORMAT } from '@ess/constants/api';
import { CHILD_CODE } from '@ess/constants/participants';

import { theme } from '@tourop/config/theme';
import { ageSettings } from '@tourop/config/searchForm/birthdate';

import { manualProvidedDateValidator } from '@ess/utils';

import usePopperPositioning from '@ess/hooks/usePopperPositioning';
import useOnClickOutside from '@ess/hooks/useOnClickOutside';

import Modal from '@ess/ui/Modal';
import FlexBox from '@ess/ui/FlexBox';
import TextInput from '@ess/ui/Form/TextInput';
import Drawer from '@ess/ui/Drawer';

import MonthAndYearsSelect from './ReplacedComponents/MonthAndYearsSelect';

export type BirthDatePickerProps = {
  mobileWithDrawer?: any
  dateValue: any
  dateInputValue: string
  minDate?: any
  maxDate?: any
  dateId: string
  datePlaceholder: string
  error?: FieldError
  readOnly?: boolean
  appendTo?: Element | null
  personType?: string
  onCalendarChange?: (date: any) => void
  onInputDateChange?: (date: any) => void
  onFocus?: (isFocused: boolean) => void
  onBlur?: (date: any) => void
  onClose?: () => void
}

const defaultProps = {
  mobileWithDrawer: false,
  error: '',
  personType: CHILD_CODE,
  minDate: moment().subtract(ageSettings[CHILD_CODE].max, 'years'),
  maxDate: moment(),
  readOnly: false,
  appendTo: undefined,
  onChange: undefined,
  onClose: undefined,
  onBlur: undefined,
  onFocus: undefined,
  onCalendarChange: undefined,
  onInputDateChange: undefined,
};

const getDisplayDateFormat = (date: string) => {
  const displayDateFormat = moment(date, DATE_REQUEST_FORMAT, true);

  return moment(displayDateFormat).isValid() ? displayDateFormat.format(DATE_DISPLAY_FORMAT) : date;
};

const BirthDatePicker = ({
  mobileWithDrawer,
  dateValue,
  dateInputValue,
  minDate,
  maxDate,
  personType,
  dateId,
  datePlaceholder,
  error,
  appendTo,
  onCalendarChange,
  onInputDateChange,
  onClose,
  onFocus,
  onBlur,
}: BirthDatePickerProps) => {
  const inputContainer = useRef<HTMLDivElement>();
  const [calendarKey, setCalendarKey] = useState(nanoid());
  const [isFocused, setIsFocused] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [dateInputElement, setDateInputElement] = useState<HTMLInputElement | null>(null);
  const [isCalendarChange, setIsCalendarChange] = useState(false);
  const [isPasteEvent, setIsPasteEvent] = useState(false);
  const mobile = useMediaQuery({ minWidth: 0, maxWidth: 768 });
  const { styles, attributes } = usePopperPositioning({
    targetElementRef: inputContainer,
    popperElementRef: popperElement,
    zIndex: theme.zIndex.modal,
  });

  /**
   * Indicates if day is outside of range.
   * @param day
   */
  const isOutsideRange = (day: any) => {
    const hasRange = minDate !== null && maxDate !== null;
    return hasRange ? day.isAfter(maxDate) || day.isBefore(minDate) : false;
  };

  /**
   * Date input paste handler.
   * @param event
   */
  const onPasteHandler = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIsPasteEvent(true);

    const clipboardValue = event.clipboardData.getData('Text');

    setInputValue(trim(clipboardValue));

    setTimeout(() => {
      setIsPasteEvent(false);
    }, 200);
  };

  /**
   * Date input change handler.
   * @param event
   */
  const onBlurHandler = (event: React.FocusEvent): void => {
    if (dateInputElement !== null) {
      const relatedTarget = event.relatedTarget as Node;
      const date = manualProvidedDateValidator({
        date: dateInputElement.value,
        allowSetAge: true,
      });

      if (date !== null && onBlur) {
        if (popperElement !== null && typeof popperElement === 'object' && 'contains' in popperElement
          && (!popperElement.contains(relatedTarget) || mobile)) {
          onBlur(date);
          setInputValue(date);

          if (!mobile) {
            setIsFocused(false);
          }
        }
      }
    }
  };

  /**
   * On focus handler.
   * @param focused
   */
  const onFocusHandler = ({ focused }: { focused: boolean }): void => {
    setIsFocused(focused);
  };

  /**
   * Date input change handler.
   * @param startDate
   */
  const onChangeHandler = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!isPasteEvent) {
      const value = event.target.value;
      const date = manualProvidedDateValidator({
        date: value, maxDate: moment(), allowSetAge: true,
      });

      setIsCalendarChange(false);

      if (onInputDateChange && date !== null && value.length > 6) {
        onInputDateChange(date);
        setInputValue(date);
      } else {
        setInputValue(value);
      }
    }
  }, 100);

  /**
   * Calendar date change handler.
   * @param value
   */
  const onCalendarChangeHandler = (value: any) => {
    setIsCalendarChange(true);

    if (onCalendarChange) {
      onCalendarChange(value);
      setInputValue(value);
    }
  };

  /**
   * Close calendar handler.
   */
  const closeCalendar = () => {
    setIsFocused(false);

    if (dateInputElement !== null) {
      const date = manualProvidedDateValidator({
        date: dateInputElement.value,
        allowSetAge: true,
      });

      if (onInputDateChange && date !== null) {
        onInputDateChange(date);
        setInputValue(date);
      }
    }

    if (onClose) {
      onClose();
    }
  };

  const dateInput = (readOnly = false) => (
    <TextInput
      isClearable
      onClear={() => { setInputValue(''); }}
      style={{ cursor: 'pointer' }}
      ref={setDateInputElement as any}
      name={dateId}
      maxLength={10}
      readOnly={readOnly}
      error={error}
      placeholder={datePlaceholder}
      value={getDisplayDateFormat(inputValue)}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      onFocus={() => setIsFocused(true)}
      onPaste={onPasteHandler}
    />
  );

  useOnClickOutside(popperElement, closeCalendar, dateInputElement !== null ? [dateInputElement] : []);

  useEffect(() => {
    if (!isCalendarChange && getDisplayDateFormat(dateInputValue)) {
      setCalendarKey(nanoid());
      setInputValue(getDisplayDateFormat(dateInputValue));
    }
  }, [dateInputValue, isCalendarChange]);

  useEffect(() => {
    if (onFocus) {
      onFocus(isFocused);
    }
  }, [isFocused]);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        closeCalendar();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const content = (
    <FlexBox flexDirection="column" minWidth="100%" minHeight="305px" >
      {isFocused && mobile && !isMobileOnly && (
      <FlexBox width="auto" backgroundColor="#e9e9e9" p="small">
        {dateInput(false)}
      </FlexBox>
      )}
      <FlexBox width="auto" margin="auto">
        <DayPickerSingleDateController
          key={calendarKey}
          date={dateValue}
          navNext={<></>}
          navPrev={<></>}
          onDateChange={onCalendarChangeHandler}
          focused={isFocused}
          initialVisibleMonth={() => (dateValue !== null ? dateValue : moment())}
          onFocusChange={onFocusHandler}
          renderMonthElement={({ onMonthSelect, onYearSelect, month }) => (
            <MonthAndYearsSelect
              personType={personType}
              maxDate={maxDate}
              minDate={minDate}
              onYearSelect={onYearSelect}
              onMonthSelect={onMonthSelect}
              month={month}
            />
          )}
          hideKeyboardShortcutsPanel
          numberOfMonths={1}
          onClose={onClose}
        />
      </FlexBox>
    </FlexBox>
  );
  return (
    <>
      <FlexBox ref={inputContainer as any}>
        {dateInput(mobile)}
      </FlexBox>
      {isMobileOnly && mobileWithDrawer && (
        <Drawer
          ref={setPopperElement as any}
          position="bottom"
          showOverlay={mobile}
          title={datePlaceholder}
          isOpen={isFocused}
          onClose={closeCalendar}
        >
          {content}
        </Drawer>
      )}
      {isFocused && isMobileOnly && !mobileWithDrawer && (
      <FlexBox style={{
        position: 'absolute', zIndex: '9999', width: '100%', top: '50px', left: '0', backgroundColor: 'white', height: 'auto',
      }}
      >
        {content}
      </FlexBox>
      )}
      {!isMobileOnly && (
        <Modal
          ref={setPopperElement as any}
          width="fit-content"
          showOverlay={mobile}
          theme="white"
          title={datePlaceholder}
          isOpen={isFocused}
          showCloseIcon={false}
          onClose={closeCalendar}
          appendTo={appendTo}
          positionedByPopper={!mobile}
          {...!mobile ? { ...attributes.popper, style: styles.popper } : {}}
        >
          {content}
        </Modal>
      )}
    </>
  );
};

BirthDatePicker.defaultProps = defaultProps;

export default memo(BirthDatePicker);
