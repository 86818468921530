import ReactDOM from 'react-dom';
import {
  get, has, isEmpty, isEqual, merge, omit, set, uniqBy,
} from 'lodash-es';
import * as Sentry from '@sentry/react';
import axios, { AxiosError, CancelTokenSource } from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import { produce } from 'immer';

import {
  IConditions, IOffer, ISearchBase, Components,
} from '@ess/types';

import { ONLINE_SERVICE_TIMEOUT } from '@ess/constants/api';

import { getParticipantsSearchSchema, getUngroupedOperators } from '@ess/utils';

import { getSortedItems } from './utils/getSearchResultsItem';
import { getRegionList, getSkiRegionList } from './utils/parseRegions';

import { compareConditions, IViewFilterList, RequestBuilder } from './requestBuilder';
import {
  conditionsDepth,
  conditionsSections,
  emptyIV5FieldStatus,
  getViewByName,
  IElementStatus,
  INetworkStatus,
  InternalDataStatus,
  IParamOptions,
  IV5DataSet,
  IV5engine,
  IV5EngineInternal,
  IV5FieldStatus,
  IViewObject,
  IViewObjectMountable,
  IViewObjects,
  IViewOfferMounted,
  OnlineAction,
  paramsSelector, priorityOrder,
  View,
} from './interfaces';
import { OfferOnline } from './offeronline';
import sendRequest, { promiseRequest } from './request';
import { fixDeprecatedOfferArray } from './deprecatedfix';

let stateChangeCounter = 0;

export const debugLogEnabled = false;

export const getStateChangeCounter = (): number => {
  stateChangeCounter++;
  return stateChangeCounter;
};

export const emptyDataState = () => ({
  isLoading: false,
  isInitialized: false,
  hasResults: false,
  isError: false,
  isReady: false,
  resultSetId: getStateChangeCounter(),
} as IV5DataSet);

interface IResultSubSet<Type> {
  subSets: Type[]
}

interface IResultParent<Type> {
  conditions: any
  parent: Type
}

interface IResultSetsSelectedObject extends IResultParent<IResultSetsFilteredAndGrouped> {
  dataOfferList: IViewObjects
  dataOfferListFieldValues: IViewFilterList
}

interface IResultSetsFilteredAndGrouped extends IResultParent<IResultSetsFiltered>, IResultSubSet<IResultSetsSelectedObject> {
  dataGroupedList: IViewObjects
}

interface IResultSetsFiltered extends IResultParent<IResultSetsUnfiltered>, IResultSubSet<IResultSetsFilteredAndGrouped> {
  dataRegionList: IViewObjects
  dataSkiRegionList: IViewObjects
  dataFieldValues: IViewFilterList
}

interface IResultSetsUnfiltered extends IResultParent<V5dataprovider>, IResultSubSet<IResultSetsFiltered> {
  offerOnlineActions: { [index: string]: IOnlineActions }
  dataUnfilteredFieldValues: IViewFilterList
  accessed: any;
  created: any;
}

interface IOnlineActions {actions: { [index: string]: IV5FieldStatus }}

const cmpArray = (a1: any[], a2: any[]) => {
  if (a1.length !== a2.length) {
    return false;
  }
  return a1.every((v, k) => Object.is(v, a2[k]));
};

/*
    Structure Example:
    base:object<Type>:
      subSet:object<TargetType>[]:
          conditions: any
          parent: object<Type>
          subSet: object<TargetSubSetType>[]
  */
const getResults = <
  Type extends IResultSubSet<TargetType>,
  TargetSubSetType,
  TargetType extends IResultParent<Type> | (IResultParent<Type> & IResultSubSet<TargetSubSetType>)
>
  (srcobject: Type, conditions: any): &TargetType => {
  if (srcobject.subSets !== undefined) {
    const found = srcobject.subSets.find((value) => isEqual(value.conditions, conditions));
    if (found !== undefined) {
      return found;
    }
  }
  const newObject = { conditions, parent: srcobject } as TargetType;
  const x = newObject as IResultSubSet<TargetSubSetType>;
  if (x !== undefined) {
    x.subSets = [];
  }
  srcobject.subSets.push(newObject);
  return newObject;
};

interface IActiveRequest {
  action: any
  offer: any
  cancelTokenSource: CancelTokenSource
}

interface IParamsSelectorObject {
  trace?: string[]
  section: string
  value: any
  func: paramsSelector
  setter: any
  uncommitted: boolean
  selectorDependency: any
  dependency: any[]
}

export class V5dataprovider implements IV5engine, IV5EngineInternal {
  // Api server address
  APIserver: string;

  // Url used to obtain token
  getTokenUrl: string;

  searchbase: ISearchBase = {};

  // contain all searchresults
  subSets: IResultSetsUnfiltered[] = [];

  // workaround: mixed conditions (filter+regions), because in app we keep them separate
  conditionsFilter: any;

  // actual searchparams
  immerParams: any;

  // destructor timer, we want to reuse v5 provider in case of some dirty remont in react, so we delay destruction
  Destructor: any;

  // list of all mounted object with results, used to notify react (by setstate) about change of searchparams
  mountedList = [] as IViewObjectMountable[];

  // list of all mounted offers view (useOfferOnline) , used to notify about online actions
  mountedOfferList: IViewOfferMounted[];

  // list of active online requests (status,places etc)
  offerRequestsActive: IActiveRequest[];

  // queuest online requests
  offerRequestsQueue: any[];

  // timer for postponed (backkgroud) taskts
  timer: any;

  // indicate, that some new object were created or updated, so check them during postponed event
  runCheckForWorkingList: boolean;

  // default fields list for each view
  fieldList: string[][] = Array(View._LENGTH);

  // list of all mounted params selectors
  paramsSelectors = [] as IParamsSelectorObject[];

  // network status object
  networkStatus: INetworkStatus = { statuses: {}, isOnline: true, reset: () => { this.Reset(); } };

  // network status target (setState setter)
  networkStatusTargets = new Set<(n: number) => void>();

  // indicate, that we are in network failed state, so do not send any new requests
  requestsDisabled = false;

  getNetworkStatus() {
    return { status: this.networkStatus, targets: this.networkStatusTargets };
  }

  setimer() {
    if (this.timer === null) {
      this.timer = setTimeout(() => {
        this.ontimer();
      }, 0);
    }
  }

  ontimer() {
    this.timer = null;
    if (this.runCheckForWorkingList) {
      this.runCheckForWorkingList = false;
      this.checkWorkingList();
    }
  }

  setRunCheckForWorkingList() {
    if (!this.runCheckForWorkingList) {
      this.runCheckForWorkingList = true;
      this.setimer();
    }
  }

  SetOrder(view: View, order: string[]) {
    if (view === View.OfferList) {
      this.paramsToCommit.orderOffers = order;
    }
    if (view === View.GroupedList) {
      this.paramsToCommit.orderGroups = order;
    }
  }

  SetPriorityOrder(view: View, order: priorityOrder) {
    if (view === View.OfferList) {
      this.paramsToCommit.orderPriorityOffers = order;
    }
  }

  getOrderPriority(view: View): priorityOrder | null {
    if (view === View.OfferList) {
      return this.immerParams.orderPriorityOffers.p;
    }
    return null;
  }

  getOrder(view: View): string[] {
    if (view === View.GroupedList) {
      return this.immerParams.orderGroups.p;
    }
    if (view === View.AccommodationSupplementary) {
      return ['Base.FirstPerson'];
    }
    if (view === View.OfferList) {
      return this.immerParams.orderOffers.p;
    }
    return [];
  }

  setOnlineFunc: any;

  cleanupTimer: any;

  cleanup() {
    const timenow = new Date();
    for (let i = 0; i < this.subSets.length; i++) {
      const subset = this.subSets[i];
      // @ts-ignore
      if ((timenow - subset.accessed) > (15 * 60 * 1000)) {
        this.subSets.splice(i, 1);
        i--;
      }
    }
    this.cleanupTimer = setTimeout(() => { this.cleanup(); }, 5000);
  }

  constructor(APIserver: string, getTokenUrl: string) {
    this.APIserver = APIserver;
    this.getTokenUrl = getTokenUrl;
    this.Destructor = null;
    this.paramsToCommit = {};
    this.timer = null;
    this.immerParams = {
      filter: { p: {}, hash: '' },
      regions: { p: {}, hash: '' },
      selectedObject: { p: '', hash: '' },
      groupType: { p: 'Accommodation.XCode', hash: '' },
      search: null,
      orderGroups: { p: ['Base.Price.FirstPerson'] },
      orderOffers: { p: ['Base.Price.FirstPerson'] },
      orderPriorityOffers: { p: null },
    };
    this.setOnlineFunc = () => {
      this.setOnline();
    };
    window.addEventListener('online', this.setOnlineFunc);
    this.cleanup();
    this.conditionsFilter = merge({}, this.immerParams.filter ?? {}, this.immerParams.regions ?? {});
    this.runCheckForWorkingList = false;
    this.offerRequestsActive = [];
    this.offerRequestsQueue = [];
    this.mountedOfferList = [];
  }

  setSearchbase(searchbase: ISearchBase) {
    this.searchbase = searchbase;
  }

  // getcurrent conditions for specified section using modifiers from paramOptions
  getCondtitionsSection(section: string, paramOptions: IParamOptions): any {
    if (section === 'details') {
      return {
        p: paramOptions?.offerDetailsId ? {
          offerDetailsId: paramOptions.offerDetailsId,
        } : {},
        hash: '',
      };
    }
    if (section === 'dataAccommodationSupplementary') {
      return {
        p: paramOptions?.offerDetailsId ? {
          Base: {
            OfferId: paramOptions.offerDetailsId,
          },
        } : {},
        hash: '',
      };
    }
    if (section === 'groupType' && paramOptions?.applyparams?.groupType) {
      return merge({ hash: '' },
        this.getParams(section, false) ?? {},
        { p: paramOptions.applyparams?.groupType ?? {} });
    }

    if (section === 'groupType' && paramOptions?.orderPriorityOffers === null) {
      return merge({ hash: '' },
        this.getParams(section, false) ?? {},
        { p: null });
    }

    if (section === 'selectedObject' && (paramOptions?.applyparams?.selectedObject)) {
      return { p: paramOptions.applyparams.selectedObject, hash: '' };
    }

    if (section === 'selectedObject' && (paramOptions?.ignoreObject ?? false)) {
      return { p: '', hash: '' };
    }

    if (section === 'search' && (paramOptions?.ignoreGeo ?? false)) {
      const nogeo = omit(this.getParams(section, paramOptions?.uncommitted?.[section] ?? false),
        'p.Base.DestinationLocation.Geo');
      return set(nogeo, 'p.Base.DestinationLocation.Geo.Ploygon.Points',
        [[-90, -180], [-90, 180], [90, 180], [90, -180]]);
    }

    if (section === 'search' && (paramOptions?.applyparams?.search ?? false)) {
      const searchParams = this.getParams(section, paramOptions?.uncommitted?.[section] ?? false);
      const newParams = paramOptions.applyparams?.search;
      return {
        ...searchParams,
        p: {
          ...searchParams.p,
          ...newParams,
        },
      };
    }

    if (section === 'filter' && paramOptions.ignoreFilters) {
      return { p: {}, hash: '' };
    }

    if (section === 'filter' && paramOptions.useObjectForFilters) {
      const selectedObject = this.getCondtitionsSection('selectedObject', paramOptions);
      if (isEmpty(selectedObject?.p)) {
        return null;
      }
      return merge({ hash: '' }, this.getParams(section, paramOptions?.uncommitted?.[section] ?? false) ?? {},
        this.getParams('regions', paramOptions?.uncommitted?.regions ?? false) ?? {},
        { p: paramOptions.applyparams?.filter ?? {}, hash: '' },
        { p: { Accommodation: { XCode: [Number(selectedObject.p)] } }, hash: '' });
    }
    // sd

    if (section === 'filter' && !paramOptions.regionRequest) {
      return merge({ hash: '' }, this.getParams(section, paramOptions?.uncommitted?.[section] ?? false) ?? {},
        this.getParams('regions', paramOptions?.uncommitted?.regions ?? false) ?? {},
        { p: paramOptions.applyparams?.filter ?? {} });
    }

    return this.getParams(section, paramOptions?.uncommitted?.[section] ?? false);
  }

  getResultSetsUnfiltered(paramOptions: IParamOptions): IResultSetsUnfiltered {
    const main = getResults<V5dataprovider, IResultSetsFiltered, IResultSetsUnfiltered>(
      this, this.getCondtitionsSection('search', paramOptions),
    );
    main.accessed = new Date();
    return main;
  }

  getResultSetsDetails(paramOptions: IParamOptions): IResultSetsUnfiltered {
    const main = getResults<V5dataprovider, IResultSetsFiltered, IResultSetsUnfiltered>(
      this, this.getCondtitionsSection('details', paramOptions),
    );
    main.accessed = new Date();
    return main;
  }

  getResultSetsSupplementary(paramOptions: IParamOptions): IResultSetsUnfiltered {
    const main = getResults<V5dataprovider, IResultSetsFiltered, IResultSetsUnfiltered>(
      this, this.getCondtitionsSection('dataAccommodationSupplementary', paramOptions),
    );
    main.accessed = new Date();
    return main;
  }

  getResultSetsFiltered(paramOptions: IParamOptions): IResultSetsFiltered {
    return getResults<IResultSetsUnfiltered, IResultSetsFilteredAndGrouped, IResultSetsFiltered>(
      this.getResultSetsUnfiltered(paramOptions), this.getCondtitionsSection('filter', paramOptions),
    );
  }

  getResultSetsFilteredAndGrouped(paramOptions: IParamOptions): IResultSetsFilteredAndGrouped {
    return getResults<IResultSetsFiltered, IResultSetsSelectedObject, IResultSetsFilteredAndGrouped>(
      this.getResultSetsFiltered(paramOptions), this.getCondtitionsSection('groupType', paramOptions),
    );
  }

  getResultSetsSelectedObject(paramOptions: IParamOptions): IResultSetsSelectedObject {
    return getResults<IResultSetsFilteredAndGrouped, never, IResultSetsSelectedObject>(
      this.getResultSetsFilteredAndGrouped(paramOptions), this.getCondtitionsSection('selectedObject', paramOptions),
    );
  }

  UpdateSearchParams(params: (draft: IConditions) => void) {
    this.paramsToCommit.search = produce(
      this.paramsToCommit.search !== undefined
        ? this.paramsToCommit.search
        : this.immerParams.search.p, params,
    );
  }

  // List of all view objects which require watching
  workingList = new Set<IViewObject>();

  // get conditions dependency , used to compare array with Object.is, before using more complex isEqual
  getConditionsDependency(view: View, paramOptions: IParamOptions) {
    const names = ['search'];
    switch (view) {
      // @ts-ignore
      case View.OfferDetails:
        return paramOptions.offerDetailsId ? [paramOptions.offerDetailsId] : [];
      // eslint-disable-next-line no-fallthrough
      // @ts-ignore
      case View.AccommodationSupplementary:
        return paramOptions.offerDetailsId ? [paramOptions.offerDetailsId] : [];
      // eslint-disable-next-line no-fallthrough
      // @ts-ignore
      case View.OfferList:
        if (!paramOptions.ignoreObject) {
          names.push('selectedObject');
        }
      // @ts-ignore
      // eslint-disable-next-line no-fallthrough
      case View.GroupedList:
        names.push('groupKey');
        if (paramOptions.useObjectForGroup && view === View.GroupedList) {
          names.push('selectedObject');
        }
      // @ts-ignore
      // eslint-disable-next-line no-fallthrough
      case View.FieldValues:
        names.push('regions');
      // @ts-ignore
      // eslint-disable-next-line no-fallthrough
      case View.RegionList:
      // @ts-ignore
      // eslint-disable-next-line no-fallthrough
      case View.SkiRegionList:
        if (!paramOptions.ignoreFilters) {
          names.push('filter');
        }
        if (paramOptions.useObjectForFilters) {
          names.push('selectedObject');
        }
      // @ts-ignore
      // eslint-disable-next-line no-fallthrough
      case View.UnfilteredFieldValues:
        return [...names.map((name) => this.immerParams[name]),
          ...(paramOptions.uncommitted ? names.map((name) => this.paramsToCommit[name]) : [])];
      default:
        return [this.immerParams, this.paramsToCommit];
    }
    // sd
  }

  // Return View object wtih results, which could be mounted, and receive notifications
  // about change via provided calllback (usually useState setter)
  GetMountableObject(view: View, setViewState: any, autoDataFetch: boolean, paramOptions: IParamOptions,
    fieldList: string[] = [], getterCacheRef: any = { current: {} }):
    IViewObjectMountable {
    const gc = getterCacheRef.current;
    const order = this.getOrder(view);
    const orderPriority = has(paramOptions, 'orderPriorityOffers')
      ? paramOptions.orderPriorityOffers ?? null
      : this.getOrderPriority(view);

    const fl = fieldList.length > 0 ? fieldList : this.fieldList[view];
    let vo: IViewObject;
    const conditionsDependency = this.getConditionsDependency(view, paramOptions);
    if (gc.vo && cmpArray(conditionsDependency, gc.conditionsDependency)
      && isEqual(gc.paramOptions, paramOptions) && isEqual(gc.vo.fieldList, fl)
      && isEqual(order, gc.vo.order)
      && (orderPriority ? isEqual(orderPriority, gc.vo.orderPriority) : true)
      && gc.vo.type === view
    ) {
      vo = gc.vo;
    } else {
      vo = this.getDataObject(view, fl, order, orderPriority, {
        ...paramOptions,
        regionRequest: view === View.RegionList || view === View.SkiRegionList,
      });
      gc.vo = vo;
      gc.paramOptions = paramOptions;
      gc.conditionsDependency = conditionsDependency;
    }

    if (autoDataFetch) {
      if (!vo.state.isReady && !vo.state.isLoading) {
        if (this.getVOConditions(vo).some((v) => v === null)) {
          vo.state = { ...emptyDataState(), isReady: true } as IV5DataSet;
        } else {
          vo.state = { ...emptyDataState(), isLoading: true, resultSetId: vo.state.resultSetId } as IV5DataSet;
          this.workingList.add(vo);
        }
      }
    }

    this.setRunCheckForWorkingList();
    const oldmo = this.mountedList.find((mo) => setViewState === mo.setViewState);
    if (oldmo !== undefined) {
      oldmo.vo = vo;
      oldmo.currentStatus = { ...vo.state };
      return oldmo;
    }
    return {
      parent: this, vo, setViewState, currentStatus: { ...vo.state }, paramOptions,
    } as IViewObjectMountable;
  }

  mount(mo: IViewObjectMountable) {
    this.mountedList.push(mo);
  }

  unmount(mo: IViewObjectMountable) {
    const index = this.mountedList.findIndex((v) => v === mo);
    if (index !== -1) {
      this.mountedList.splice(index, 1);
    }
  }

  checkWorkingList() {
    this.checkMountedForPendingRequests();
  }

  // processTravelSearch response, extract data for requested views
  processAnswer(requestedViews: IViewObject[], data: Components.Responses.Travelsearchresponse) {
    requestedViews.forEach((vo: IViewObject) => {
      const s = emptyDataState();
      s.isInitialized = true;
      s.isReady = true;
      switch (vo.type) {
        case View.OfferDetails:
          // eslint-disable-next-line no-param-reassign
          vo.orgItems = fixDeprecatedOfferArray([(data as any).result]);
          // eslint-disable-next-line no-param-reassign
          vo.items = vo.orgItems.map((v: any) => ({ ...v, parent: vo, v5engine: this }));
          s.hasResults = (vo.items.length > 0);
          break;
        case View.GroupedList:
          if (vo.loadMoreRequested) {
            s.resultSetId = vo.state.resultSetId;
          }
          if (data.groupedList?.items) {
            const orgItemsStart = vo.orgItems.length;
            // eslint-disable-next-line no-param-reassign
            vo.orgItems = [...(vo.loadMoreRequested ? vo.orgItems : []),
              ...fixDeprecatedOfferArray(Object.values(data.groupedList.items))];
            // eslint-disable-next-line no-param-reassign
            vo.items = [...vo.items, ...getSortedItems(vo.orgItems.slice(orgItemsStart),
              data.groupedList.sortKeyOrderAscending)
              .map((v) => ({ ...v, parent: vo, v5engine: this }))];

            vo.previousPageBookmark = data.groupedList.pageBookmark ?? '';
          }
          // eslint-disable-next-line no-param-reassign
          vo.loadMoreRequested = false;
          s.hasMoreAtEnd = data.groupedList?.more ?? false;
          s.hasResults = (vo.items.length > 0);
          break;
        case View.AccommodationSupplementary:
          if (vo.loadMoreRequested) {
            s.resultSetId = vo.state.resultSetId;
          }

          if (data.offerList?.items) {
            const orgItemsStart = vo.orgItems.length;
            // eslint-disable-next-line no-param-reassign
            vo.orgItems = uniqBy(
              [...(vo.loadMoreRequested ? vo.orgItems : []), ...fixDeprecatedOfferArray(Object.values(data.offerList.items))],
              (o) => o.offer.Base.OfferId,
            );
            // eslint-disable-next-line no-param-reassign
            vo.items = [...vo.items, ...getSortedItems(vo.orgItems.slice(orgItemsStart),
              data.offerList.sortKeyOrderAscending)
              .map((v) => ({ ...v, parent: vo, v5engine: this }))];
          }
          s.hasMoreAtEnd = data.offerList?.more ?? false;
          // eslint-disable-next-line no-param-reassign
          vo.loadMoreRequested = false;
          s.hasResults = (vo.items.length > 0);
          break;
        case View.OfferList:
          if (vo.loadMoreRequested) {
            s.resultSetId = vo.state.resultSetId;
          }

          if (data.offerList?.items) {
            const orgItemsStart = vo.orgItems.length;
            // eslint-disable-next-line no-param-reassign
            vo.orgItems = uniqBy(
              [...(vo.loadMoreRequested ? vo.orgItems : []), ...fixDeprecatedOfferArray(Object.values(data.offerList.items))],
              (o) => o.offer.Base.OfferId,
            );
            // eslint-disable-next-line no-param-reassign
            vo.items = [...vo.items, ...getSortedItems(
              vo.orgItems.slice(orgItemsStart), data.offerList.sortKeyOrderAscending, true,
            ).map((v) => ({ ...v, parent: vo, v5engine: this }))];

            vo.previousPageBookmark = data.offerList.pageBookmark ?? '';
          }
          s.hasMoreAtEnd = data.offerList?.more ?? false;
          // eslint-disable-next-line no-param-reassign
          vo.loadMoreRequested = false;
          s.hasResults = (vo.items.length > 0);
          break;
        case View.OfferListFieldValues:
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          vo.items = data?.offerListFieldValues?.[vo.fieldList[0]] ?? {};
          s.hasResults = (vo.items.length > 0);
          break;
        case View.RegionList:
          // eslint-disable-next-line no-param-reassign
          vo.items = getRegionList(data);
          s.hasResults = (vo.items.length > 0);
          break;
        case View.SkiRegionList:
          // eslint-disable-next-line no-param-reassign
          vo.items = getSkiRegionList(data);
          s.hasResults = (vo.items.length > 0);
          break;
        default:
          if (debugLogEnabled) {
            console.log('Unsupported view type:', vo.type);
          }
      }

      if (s.hasMoreAtEnd) {
        s.loadMoreAtEnd = () => {
          if (vo.ajaxActive) {
            return;
          }
          // eslint-disable-next-line no-param-reassign
          vo.loadMoreRequested = true;
          this.workingList.add(vo);
          this.sendRequest([vo]);
        };
      }
      // eslint-disable-next-line no-param-reassign
      vo.dataStatus = InternalDataStatus.Done;
      // eslint-disable-next-line no-param-reassign
      vo.ajaxActive = false;
      // eslint-disable-next-line no-param-reassign
      vo.state = s;
    });
    this.setMoundedState();
  }

  /**
   *
   * Go  thru mounted list and check if there is any object which will require update
   */
  checkMountedForPendingRequests(): boolean {
    const listToSendAjax: IViewObject[] = [];
    this.workingList.forEach((vo) => {
      const mounted = [...this.mountedList].some((mo) => vo === mo.vo);
      if ((vo.state.isLoading || vo.loadMoreRequested) && !vo.ajaxActive) {
        if (mounted) {
          listToSendAjax.push(vo);
        } else {
          // eslint-disable-next-line no-param-reassign
          vo.state.isLoading = false;
          this.workingList.delete(vo);
        }
      }
    });
    if (this.requestsDisabled) {
      return listToSendAjax.length !== 0;
    }
    this.sendRequest(listToSendAjax);
    return listToSendAjax.length !== 0;
  }

  // helper function to get all conditions dependency
  getVOConditions(vo: IViewObject): any[] {
    const conditions = [];
    for (let c = vo.parent; c.parent !== undefined; c = c.parent) {
      conditions.push(c.conditions);
    }
    return conditions.reverse();
  }

  getConditionsForViewObject(data: IViewObject, section: conditionsSections): any {
    const condlist = this.getVOConditions(data);
    switch (section) {
      case 'selectedObject':
        return condlist[conditionsDepth.SlectedObject]?.p ?? '';
      case 'filter':
        return condlist[conditionsDepth.Filtered]?.p ?? {};
      case 'groupKey':
        return condlist[conditionsDepth.GroupKey]?.p ?? '';
      case 'search':
        return condlist[conditionsDepth.Search]?.p ?? {};
      default:
        return [];
    }
  }

  // ********* Network Status part

  // check status of query
  checkQuery(query: string, require200: boolean) {
    const newquery = { status: 'checking' };
    const source = axios.CancelToken.source();
    axios.get(query, { cancelToken: source.token }).then((d) => {
      if (d.status === 200) {
        newquery.status = 'ok';
      } else {
        newquery.status = 'error';
      }
    }).catch((error) => {
      if (error.message === 'to') {
        newquery.status = 'error';
        return;
      }

      Sentry.withScope((scope) => {
        const { status = 0, responseURL } = error?.request ?? {};

        scope.setContext('Error Full', {
          errorFull: JSON.stringify(error),
          request: error?.request ? JSON.stringify(error?.request) : {},
        });

        scope.setTransactionName(`HTTP error: ${responseURL ?? query} ${status} ${error?.message ?? ''}`);
        Sentry.captureException(new Error(error));
      });

      if (!require200 && error.response?.status === 404) {
        newquery.status = 'ok';
      } else {
        newquery.status = 'error';
      }
    }).finally(() => {
      this.networkStatusTargets.forEach((v) => v(getStateChangeCounter()));
    });

    setTimeout(() => source.cancel('to'), 5000);
    return newquery as IElementStatus;
  }

  // run additional query to help determine source of network problem
  checkNetworkParts() {
    this.networkStatus.statuses.Server = this.checkQuery('/pingsvr/', false);
    this.networkStatus.statuses.Service = this.checkQuery('/pingsvc/', true);
  }

  // retry to run failed/queued queries
  retryNetworkConnection() {
    if (!window.navigator.onLine) {
      this.setNetworkStatus(false);
      return;
    }
    this.checkNetworkParts();
    this.networkStatusTargets.forEach((v) => v(getStateChangeCounter()));

    this.requestsDisabled = false;
    if (!this.checkMountedForPendingRequests()) {
      this.setNetworkStatus(true);
    }
  }

  // handler of browser online event
  setOnline() {
    if (this.networkStatus.statuses.Connection) {
      this.networkStatus.statuses.Connection.status = 'ok';
    }
    if (this.networkStatus.statuses.Query) {
      this.networkStatus.statuses.Query.status = 'checking';
    }
    this.checkNetworkParts();
    this.networkStatusTargets.forEach((v) => v(getStateChangeCounter()));
    this.retryNetworkConnection();
  }

  // update network status and notify components abouange
  setNetworkStatus(ok: boolean, error: string | undefined = undefined) {
    if (!ok) {
      if (!window.navigator.onLine || this.networkStatus.statuses.Connection) {
        this.networkStatus.statuses.Connection = { status: window.navigator.onLine ? 'ok' : 'error' };
      }
      if (window.navigator.onLine) {
        this.checkNetworkParts();
      }

      this.networkStatus.statuses.Query = { status: 'error' };
      this.networkStatus.isOnline = false;
      this.requestsDisabled = true;
      if (error !== undefined) {
        this.networkStatus.errorMessage = error;
      }
      this.networkStatus.retryButton = () => {
        this.retryNetworkConnection();
      };
    }

    if (!this.networkStatus.isOnline) {
      if (ok) {
        this.networkStatus.statuses = {};
        this.networkStatus.isOnline = true;
        this.requestsDisabled = false;
      }
    }
    this.networkStatusTargets.forEach((v) => v(getStateChangeCounter()));
  }

  /**
   * Returns modified conditions.
   * @param postData
   */
  fixConditions(postData: any) {
    const searchBase: any = this.searchbase;
    const searchConditions = postData.conditions.search;
    const filterConditions = postData.conditions.filter;
    const searchOperators = searchConditions.Base?.Operator;
    const defaultOperators = Object.keys(this.searchbase['Base.Operator']?.list?.values ?? []);
    const selectedOperators = searchOperators && searchOperators.length ? searchOperators : defaultOperators;

    const priceValue = searchConditions.Base?.Price as any;
    const priceType = Object.keys(priceValue ?? {}).filter((item) => !isEmpty(priceValue[item]))[0];
    const maxPrice = searchBase[`Base.Price.${priceType}.Max`]?.num?.max;
    const { Min, Max } = priceValue?.[priceType] ?? {};

    return {
      ...postData,
      conditions: {
        ...postData.conditions,
        search: {
          ...searchConditions,
          Base: {
            ...searchConditions.Base,
            ParticipantsList: getParticipantsSearchSchema(searchConditions.Base?.ParticipantsList as any),
            Operator: getUngroupedOperators(selectedOperators),
            Price: {
              [priceType]: (Max === maxPrice) ? { Min } : {
                Min,
                Max,
              },
            },
          },
          Accommodation: {
            ...searchConditions.Accommodation,
            ...searchConditions?.Accommodation?.Attributes ? {
              Attributes: searchConditions.Accommodation.Attributes.map((attribute: string) => (
                attribute.charAt(0) === '+' ? attribute : `+${attribute}`),
              ),
            } : {},
          },
        },
        filter: {
          ...filterConditions,
          Accommodation: {
            ...filterConditions.Accommodation,
            ...filterConditions?.Accommodation?.Attributes ? {
              Attributes: filterConditions.Accommodation.Attributes.map((attribute: string) => (
                attribute.charAt(0) === '+' ? attribute : `+${attribute}`),
              ),
            } : {},
          },
        },
      },
    };
  }

  // Temporary solution for the filters fetcher
  // TODO: proper handling of filters inside the  v5 provider
  postSearch(postData: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      sendRequest({
        getTokenUrl: this.getTokenUrl,
        baseurl: `${this.APIserver}/v5`,
        action: 'data/travel/search',
        retryCount: 3,
        postData: this.fixConditions(postData),
        cancelTokenSource: undefined,
        onSuccess: (data: any) => {
          resolve(data);
        },
        onError: (e: any) => {
          reject(e);
        },
        onCancel: () => {
          reject(new Error('lbl_cancel'));
        },
      });
    });
  }

  /*
     * check ViewObjects List State, and send request if required
     *
     */
  sendRequest(list: IViewObject[]) {
    const reqBuilder = new RequestBuilder();

    reqBuilder.getAjaxRequests(list.map((vo) => ([vo, this.getVOConditions(vo)])), this.searchbase).forEach((request) => {
      request.subscribers.forEach((vo: IViewObject) => {
        if (vo.loadMoreRequested) {
          const s = { ...vo.state };

          s.isLoadingMoreAtEnd = true;
          s.isLoadingMore = true;
          // eslint-disable-next-line no-param-reassign
          vo.state = s;
          // eslint-disable-next-line no-param-reassign
          vo.ajaxActive = true;
        } else {
          const s = emptyDataState();
          s.isInitialized = true;
          s.isLoading = true;
          // eslint-disable-next-line no-param-reassign
          vo.state = s;
          // eslint-disable-next-line no-param-reassign
          vo.ajaxActive = true;
        }
      });

      sendRequest({
        getTokenUrl: this.getTokenUrl,
        baseurl: `${this.APIserver}/v5`,
        action: request.get ?? 'data/travel/search',
        retryCount: 3,
        postData: request.ajax,
        cancelTokenSource: undefined,
        onSuccess: (data) => {
          this.setNetworkStatus(true, '');
          const t0 = performance.now();
          // eslint-disable-next-line no-param-reassign
          this.processAnswer(request.subscribers, data);
          if (debugLogEnabled) {
            const t1 = performance.now();
            console.log(`AjaxResponseProcess took ${t1 - t0} milliseconds.`);
          }
        },
        onError: (e) => {
          if (!e.status) {
            this.setNetworkStatus(false, 'Network error');
            request.subscribers.forEach((vo: IViewObject) => {
              // eslint-disable-next-line no-param-reassign
              vo.ajaxActive = false;
            });
          } else {
            this.setNetworkStatus(true, '');
            request.subscribers.forEach((vo: IViewObject) => {
              const s = emptyDataState();
              s.isInitialized = true;
              s.isLoading = false;
              s.isError = true;
              s.isReady = true;
              // eslint-disable-next-line no-param-reassign
              vo.ajaxActive = false;
              // eslint-disable-next-line no-param-reassign
              vo.state = s;
            });
          }
        },
      });
      this.setMoundedState();
    });
  }

  getViewObject(view: View, obj: any, val: string, order: string[], orderPriority: priorityOrder | null): IViewObject {
    if (obj[val] === undefined) {
      // eslint-disable-next-line no-param-reassign
      obj[val] = { viewobjects: [] } as IViewObjects;
    }
    const vos = obj[val] as IViewObjects;
    const ret = vos.viewobjects.find((vo) => (
      orderPriority ? isEqual(vo.order, order) && isEqual(vo.orderPriority, orderPriority) : isEqual(vo.order, order)),
    );
    if (ret === undefined) {
      const newviewobject = {
        state: emptyDataState(),
        order: [...order],
        orderPriority,
        items: [],
        parent: obj,
        type: view,
        orgItems: [],
        fieldList: this.fieldList[view],
        dataStatus: InternalDataStatus.Idle,
      } as IViewObject;
      vos.viewobjects.push(newviewobject);
      return newviewobject;
    }
    return ret;
  }

  getDataObject(view: View, fieldList: string[], order: string[], orderPriority: priorityOrder | null, paramOptions: IParamOptions): &IViewObject {
    switch (view) {
      case View.OfferDetails: {
        return this.getViewObject(view, this.getResultSetsDetails(paramOptions),
          'details', [], null);
      }
      case View.AccommodationSupplementary: {
        return this.getViewObject(view, this.getResultSetsSupplementary(paramOptions),
          'dataAccommodationSupplementary', [], null);
      }
      case View.SkiRegionList: {
        return this.getViewObject(view, this.getResultSetsFiltered(paramOptions),
          'dataSkiRegionList', [], null);
      }
      case View.RegionList: {
        return this.getViewObject(view, this.getResultSetsFiltered(paramOptions),
          'dataRegionList', [], null);
      }
      case View.GroupedList: {
        return this.getViewObject(view, this.getResultSetsFilteredAndGrouped(paramOptions),
          'dataGroupedList', order, null);
      }
      case View.OfferList: {
        return this.getViewObject(view, this.getResultSetsSelectedObject(paramOptions),
          'dataOfferList', order, orderPriority);
      }
      case View.OfferListFieldValues: {
        const rf = this.getResultSetsSelectedObject(paramOptions);
        if (rf.dataOfferListFieldValues === undefined) {
          rf.dataOfferListFieldValues = { fields: [] } as IViewFilterList;
        }
        const vo = rf.dataOfferListFieldValues.fields.find((v) => v.fieldList[0] === fieldList[0]);
        if (vo !== undefined) {
          return vo;
        }
        rf.dataOfferListFieldValues.fields.push({
          state: emptyDataState(),
          items: [],
          parent: rf,
          type: view,
          fieldList,
          dataStatus: InternalDataStatus.Idle,
        } as IViewObject);
        return rf.dataOfferListFieldValues.fields[rf.dataOfferListFieldValues.fields.length - 1];
      }

      default:
        throw new Error('Wrong usage');
    }
  }

  ping() {
    if (this.Destructor == null) {
      return;
    }
    clearTimeout(this.Destructor);
  }

  end() {
    if (this.Destructor != null) {
      throw new Error('Wrong usage');
    }
    this.Destructor = setTimeout(() => {
      this.destroy();
    }, 0);
  }

  destroy() {
    window.removeEventListener('online', this.setOnlineFunc);
  }

  paramsToCommit: any;

  IsSelectedObject(): boolean {
    return this.immerParams.selectedObject !== null;
  }

  SetSearchParams(params: any) {
    this.paramsToCommit.search = params;
  }

  updateParamsSelectors(changedSections: any) {
    let changed = 0;
    let triggered = 0;
    this.paramsSelectors.filter(({ section, uncommitted }) => (changedSections[section] && !uncommitted))
      .forEach((value) => {
        const newValue = value.func(get(this.immerParams[value.section], 'p', {}));
        triggered++;
        if (!isEqual(newValue, value.value)) {
          changed++;
          value.setter(getStateChangeCounter());
        }
      });
    const t1 = performance.now();
  }

  Commit(onReady?: (data: IV5DataSet, alreadyDone: boolean) => void | undefined, view?: View | undefined,
    noResetObject?: boolean, deleteCacheAfter?: number | undefined) {
    const changedSections: any = {};
    let change = false;
    this.immerParams = produce(this.immerParams, ((draft: any) => {
      Object.entries(this.paramsToCommit).forEach(([k, v]) => {
        if (draft[k] !== null && isEqual(draft[k].p, v)) {
          return;
        }
        changedSections[k] = true;
        change = true;
        // eslint-disable-next-line no-param-reassign
        draft[k] = v === null ? null : { p: v, hash: '' };
      });
    }));

    // Object resetting options
    if (!noResetObject) {
      if ((changedSections.search || changedSections.filter || changedSections.regions)
        && (this.immerParams.selectedObject === null || this.immerParams.selectedObject.p !== '')
      ) {
        this.immerParams = produce(this.immerParams, ((draft: any) => {
          changedSections.selectedObject = draft.selectedObject !== null;
          // eslint-disable-next-line no-param-reassign
          draft.selectedObject = null;
        }));
      }
    }

    if (deleteCacheAfter) {
      const timenow = new Date();
      const ru = this.getResultSetsUnfiltered({});
      if (!ru.created) {
        ru.created = timenow;
        // @ts-ignore
      } else if ((timenow - ru.created) > (deleteCacheAfter * 1000)) {
        change = true;
        this.subSets = [];
        const ru = this.getResultSetsUnfiltered({});
        ru.created = timenow;
      }
    }

    this.paramsToCommit = {};
    if (change) {
      this.conditionsFilter = merge({}, this.immerParams.filter ?? {}, this.immerParams.regions ?? {});
      this.updateParamsSelectors(changedSections);
    }

    if (change || (view !== undefined)) {
      if (view !== undefined && onReady) {
        const dataObj = this.getDataObject(view, this.fieldList[view], this.getOrder(view), this.getOrderPriority(view),
          { ignoreFilters: !noResetObject, regionRequest: view === View.RegionList || view === View.SkiRegionList });

        const { state, items } = dataObj;
        const isAlreadyDone = state.isReady && state.hasResults && !state.isError && items.length > 0;

        if (isAlreadyDone) {
          setTimeout(() => onReady({ ...dataObj.state }, true), 100);
        } else {
          if (!dataObj.ajaxActive) {
            dataObj.state.isLoading = true;
            this.workingList.add(dataObj);
          }
          const mo = this.GetMountableObject(view, () => {
            if (dataObj.state.isReady) {
              onReady({ ...dataObj.state }, false);
              this.unmount(mo);
            }
          }, true, {});
          this.mount(mo);
        }
      }
      this.updateMountedOnConditionsChange();
      this.checkWorkingList();
    }
  }

  updateMountedOnConditionsChange() {
    ReactDOM.unstable_batchedUpdates(() => {
      this.mountedList.forEach((mo) => {
        const conditions = [this.getCondtitionsSection('search', mo.paramOptions),
          this.getCondtitionsSection('filter', mo.paramOptions),
          this.getCondtitionsSection('groupType', mo.paramOptions),
          this.getCondtitionsSection('selectedObject', mo.paramOptions)];
        if (
          (!compareConditions(this.getVOConditions(mo.vo), conditions))
          || (!isEqual(mo.vo.order, this.getOrder(mo.vo.type)))
          || (!isEqual(mo.vo.orderPriority, this.getOrderPriority(mo.vo.type)))
        ) {
          mo.setViewState(getStateChangeCounter());
        }
      });
    });
  }

  triggerParamsUpdate(section: string) {
    this.paramsSelectors.filter((p) => p.uncommitted && p.section === section).forEach((value) => {
      const newValue = value.func(get(this.getParams(value.section, value.uncommitted), 'p', {}));
      if (!isEqual(newValue, value.value)) {
        value.setter(getStateChangeCounter());
      }
    });
  }

  SetFilterParams(params: any) {
    this.paramsToCommit.filter = params;
    this.triggerParamsUpdate('filter');
  }

  Revert() {
    this.paramsToCommit = {};
    this.paramsSelectors.filter((p) => p.uncommitted).forEach((value) => {
      const newValue = value.func(get(this.getParams(value.section, value.uncommitted), 'p', {}));
      if (!isEqual(newValue, value.value)) {
        value.setter(getStateChangeCounter());
      }
    });
  }

  SetRegions(params: any) {
    this.paramsToCommit.regions = params;
    this.triggerParamsUpdate('regions');
  }

  SetGroupType(params: any) {
    this.paramsToCommit.groupType = params;
  }

  SetFieldList(fieldListObject: { [index: string]: { fieldList: string[] } }) {
    const ll = Object.entries(fieldListObject);
    ll.forEach(([k, v]) => {
      const view = getViewByName(k);
      if (view === undefined) {
        throw new Error(`Wrong view name:${k}`);
      }
      this.fieldList[view] = [...v.fieldList];
    });
  }

  SetSelectedObject(params: any): boolean {
    if (isEqual(this.getParams('selectedObject', false)?.p, params)) {
      return false;
    }
    this.paramsToCommit.selectedObject = params;
    return true;
  }

  // Check for mounted Views, disconnects all unmounted

  setMoundedState() {
    const stateChangeCounter = getStateChangeCounter();
    ReactDOM.unstable_batchedUpdates(() => {
      this.workingList.forEach((vo) => {
        do {
          const ml = [...this.mountedList];
          const index = ml.findIndex((mo) => mo.vo === vo && !isEqual(mo.currentStatus, vo.state));
          if (index === -1) {
            break;
          }
          const mo = ml[index];
          mo.currentStatus = { ...vo.state };
          mo.setViewState(stateChangeCounter);
          // eslint-disable-next-line no-constant-condition
        } while (true);
        if (!vo.ajaxActive && !vo.state.isLoading && !vo.state.isLoadingMore) {
          this.workingList.delete(vo);
        }
      });
    });
  }

  Reset() {
    this.workingList.forEach((vo) => {
      this.workingList.delete(vo);
    });
    this.setNetworkStatus(true, '');
    this.SetSearchParams(null);
    this.Commit();
    this.networkStatus.statuses = {};
  }

  // ******* OnlineOffersPart *******

  /**
   * Trigger statechange(rerender) for selected offerid
   * @param offerid
   */
  triggerMountedOffers(offerid: string) {
    this.mountedOfferList.filter((value) => value.onlineOffer.Base.OfferId === offerid)
      .forEach((value) => value.statusUpdater(getStateChangeCounter()));
  }

  getParams(conditionsSection: string, uncommitted: boolean) {
    if (!uncommitted || this.paramsToCommit[conditionsSection] === undefined) {
      return this.immerParams[conditionsSection];
    }
    return { p: this.paramsToCommit[conditionsSection], hash: '' };
  }

  getParamsSelector(
    conditionsSection: string,
    ps: paramsSelector,
    setter: any,
    def = undefined,
    uncommitted = false,
    deps: (any[] | undefined) = undefined,
    selectorRef: any = { current: null },
  ): IParamsSelectorObject {
    const dependency = [this.immerParams[conditionsSection],
      uncommitted
        ? this.paramsToCommit[conditionsSection]
        : undefined, ...(deps || [ps])];
    let oldps: IParamsSelectorObject;
    if (selectorRef.current) {
      oldps = selectorRef.current;
    } else {
      const selectorDependency = get(this.getParams(conditionsSection, uncommitted), 'p', {});

      const newps = {
        // trace: (new Error('StackLog')).stack.split('\n'),
        section: conditionsSection,
        func: ps,
        selectorDependency,
        uncommitted,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        value: produce(ps(selectorDependency), () => {}) ?? def,
        setter,
        dependency,
      } as IParamsSelectorObject;
      // eslint-disable-next-line no-param-reassign
      selectorRef.current = newps;
      return newps;
    }
    if (cmpArray(dependency, oldps.dependency)) {
      return oldps;
    }
    oldps.section = conditionsSection;
    oldps.func = ps;
    if (oldps.dependency[0] !== dependency[0] || oldps.dependency[1] !== dependency[1]) {
      oldps.selectorDependency = get(this.getParams(conditionsSection, uncommitted), 'p', {});
    }
    oldps.dependency = dependency;
    const newvalue = ps(oldps.selectorDependency) ?? def;
    if (!isEqual(oldps.value, newvalue)) {
      oldps.value = produce(oldps.value, () => newvalue);
    }
    return oldps;
  }

  mountSelector(pso: IParamsSelectorObject): void {
    this.paramsSelectors.push(pso);
  }

  unmountSelector(pso: IParamsSelectorObject): void {
    const index = this.paramsSelectors.findIndex((v) => v === pso);
    if (index !== -1) {
      this.paramsSelectors.splice(index, 1);
    }
  }

  /**
   * Get status/data of online requests for selected searchparams/offerid
   */
  getOfferOnlineStatus(vo: IViewObject, offerId: string, create: boolean): any {
    const ru = this.getResultsSetUnvilteredByViewObject(vo);
    if (!ru) {
      return null;
    }
    if (ru.offerOnlineActions === undefined) {
      if (!create) {
        return null;
      }
      ru.offerOnlineActions = {};
    }
    const onlineActions = ru.offerOnlineActions[offerId];
    if (onlineActions === undefined) {
      if (!create) {
        return null;
      }
      ru.offerOnlineActions[offerId] = { actions: {} };
      return ru.offerOnlineActions[offerId];
    }
    return onlineActions;
  }

  getOfferOnlineAction<T extends boolean>({ actions }: IOnlineActions, action: OnlineAction, create: T):
    T extends true ? IV5FieldStatus : IV5FieldStatus | null {
    if (actions[action?.action] !== undefined) {
      return actions[action.action];
    }
    if (!create) {
      // @ts-ignore
      return null;
    }

    // eslint-disable-next-line no-param-reassign
    actions[action.action] = { ...emptyIV5FieldStatus };
    return actions[action.action];
  }

  offerOnlineState<T extends boolean>(vo: IViewObject, action: OnlineAction, offer: any, create: T):
    T extends true ? IV5FieldStatus : IV5FieldStatus | null {
    const status = this.getOfferOnlineStatus(vo, offer.Base.OfferId, create);
    if (status === null && !create) {
      return { ...emptyIV5FieldStatus };
    }
    return this.getOfferOnlineAction(status, action, create);
  }

  offerOnlineQueueRequest(vo: IViewObject, action: Components.Schemas.OfferOnlineAction, offer: IOffer, force?: boolean,
    requeue?: boolean, callback?: any)
    : IV5FieldStatus {
    const state = this.offerOnlineState(vo, action, offer, true);
    if (state.isLoading) {
      if (callback) {
        state.callbacks.push(callback);
      }
      if (state.isQueued && (force || requeue)) {
        // this.moveToFrontOfQueue(action, offer);
        // TODO: move to front of queue
      }
      return { ...state };
    }
    if ((state.isReady) && (!force)) {
      callback?.();
      return { ...state };
    }
    if (callback) {
      state.callbacks.push(callback);
    }
    return { ...this.offerOnlineTryToSendRequest(action, offer, state) };
  }

  offerOnlineTryToSendRequest(action: any, offer: any, currentState: IV5FieldStatus): IV5FieldStatus {
    const state = currentState;
    state.isLoading = true;
    state.isQueued = false;
    state.isError = false;
    // TODO: enable   queue
    if (this.offerRequestsActive.filter((value) => value.offer.mounted).length >= 40) {
      this.offerRequestsQueue.push([action, offer]);
      state.callbacks = state.callbacks.filter((v) => {
        v(state);
        return false;
      });
      state.isQueued = true;
    } else if (this.offerRequestsActive.length >= 70) {
      this.offerRequestsQueue.unshift([action, offer]);
      state.isQueued = true;
      state.callbacks = state.callbacks.filter((v) => {
        v(state);
        return false;
      });
      // @ts-ignore
      this.offerRequestsActive.find((value) => !value.offer.mounted).httpRequest.cancel();
    } else {
      this.offerOnlineSendRequest(action, offer, state);
    }
    this.triggerMountedOffers(offer.Base.OfferId);
    return state;
  }

  offerOnlineSetError(offer: any, currentState: IV5FieldStatus, cancelTokenSource: any) {
    const state = currentState;
    state.isLoading = false;
    state.isError = true;
    state.isReady = true;
    state.data = {
      Base: {
        Availability: {
          base: 'unknown',
        },
      },
    };

    state.callbacks = state.callbacks.filter((v) => {
      v(state);
      return false;
    });
    this.offerRequestsActive = this.offerRequestsActive.filter((v) => v.cancelTokenSource !== cancelTokenSource);
    this.triggerMountedOffers(offer.Base.OfferId);
  }

  /**
   * Offer online request handler.
   * @param action
   * @param offer
   * @param currentState
   */
  offerOnlineSendRequest(action: any, offer: any, currentState: IV5FieldStatus) {
    const state = currentState;
    const cancelTokenSource = axios.CancelToken.source();
    const currentActiveRequest = {
      action, offer, cancelTokenSource,
    } as IActiveRequest;
    this.offerRequestsActive.push(currentActiveRequest);

    const requestData = {
      actions: [action.action],
      offerIds: [offer.Base.OfferId],
      includeTFG: offer?.Base?.Price?.Total?.details?.TFGIncluded ?? false,
    };

    promiseRequest(`${this.APIserver}/v5/data/travel/checkonline`,
      requestData, 0, cancelTokenSource, ONLINE_SERVICE_TIMEOUT).then((data) => {
      try {
        state.isLoading = false;
        state.isReady = true;
        state.data = {};
        if (data?.results && data.results[0].offer && data.results.length > 0) {
          state.data = data.results[0].offer;
        }
        if (isEmpty(state.data)) {
          this.offerOnlineSetError(offer, state, cancelTokenSource);
        } else {
          this.offerRequestsActive = this.offerRequestsActive.filter((v) => v.cancelTokenSource !== cancelTokenSource);
          state.callbacks = state.callbacks.filter((v) => {
            v(state);
            return false;
          });
          this.triggerMountedOffers(offer.Base.OfferId);
        }
      } catch (e) {
        Sentry.captureException(e);
        this.offerOnlineSetError(offer, state, cancelTokenSource);
      }
    }).catch((thrown) => {
      // "ECONNABORTED"
      const { code } = thrown as AxiosError;
      if (code === 'ECONNABORTED') {
        this.offerOnlineSetError(offer, state, cancelTokenSource);
      } else if (axios.isCancel(thrown)) {
        if (debugLogEnabled) {
          console.log('Request canceled', thrown.message);
        }
        state.callbacks = state.callbacks.filter((v) => {
          v(state);
          return false;
        });
      } else if (state.retryCount > 0) {
        state.retryCount--;
        this.offerRequestsActive = this.offerRequestsActive.filter((v) => v.cancelTokenSource !== cancelTokenSource);
        this.offerOnlineSendRequest(action, offer, state);
      } else {
        this.offerOnlineSetError(offer, state, cancelTokenSource);
      }
    });
  }

  getResultsSetUnvilteredByViewObject(vo: IViewObject): IResultSetsUnfiltered | undefined {
    let resultset;
    // eslint-disable-next-line no-empty
    for (resultset = vo; resultset && resultset.parent !== this; resultset = resultset.parent) {}
    return resultset;
  }

  getOfferOnlineData(parent: IViewObject, offerId: string): any {
    const ru = this.getResultsSetUnvilteredByViewObject(parent);
    if (!ru) {
      return null;
    }
    if (ru.offerOnlineActions === undefined) {
      return null;
    }
    if (ru.offerOnlineActions[offerId] === undefined) {
      return null;
    }
    return ru.offerOnlineActions[offerId];
  }

  mountOffer(offer: OfferOnline, stateUpdater: any) {
    this.mountedOfferList.push({ onlineOffer: offer, statusUpdater: stateUpdater } as IViewOfferMounted);
  }

  unmountOffer(offer: OfferOnline) {
    this.mountedOfferList = this.mountedOfferList.filter((v) => v.onlineOffer !== offer);
  }
}
